import React from 'react';
import { ChangeEvent } from 'react';
// import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForever from 'mdi-material-ui/DeleteForever';
// import RefreshCircle from 'mdi-material-ui/RefreshCircle';
import PlusCircle from 'mdi-material-ui/PlusCircle';
import SimulatorsDropdown from './SimulatorsDropdown';
import {
  HttpHeader,
  HttpRequest,
  HttpResponse,
  // isNoBodyHttpMethod,
} from '../modules/http';
import StyledButton from './shared/StyledButtons';
import OutlinedTextField from './shared/OutlinedTextFields';
import { OutlinedMultilineTextField } from './shared/OutlinedTextFields';
import Page from './shared/Page';
import useStyles from '../modules/styles';
import { CancellablePromise, makeCancellable } from '../modules/cancellablePromise';
import { SimulatorSpec } from '../modules/simulators';
import { simulate } from '../modules/simulators';
import { useSimletTester } from '../modules/simletTester';


export default function SimletTester(props: any) {
  const classes = useStyles();
  // const history = useHistory();
  const simletTester = useSimletTester();

  const [simulator, setSimulator] = React.useState<SimulatorSpec | null>(
    simletTester.simulator || null
  );

  const httpRequest: HttpRequest = simletTester.httpRequest;
  const [requestMethod, setRequestMethod] = React.useState<string>(httpRequest.method);
  const [requestUri, setRequestUri] = React.useState<string>(httpRequest.uri);
  const [httpHeaders, setHttpHeaders] = React.useState(httpRequest.headers.toArray());
  const [requestBody, setRequestBody] = React.useState<string>(httpRequest.body);

  React.useEffect(
    () => {
      // Code that gets executed after rendering the component
      ;

      // In the optionally returned function - code that gets
      // executed on component unmounting. Do cleanup in it
      return () => {
        simletTester.simulator = simulator;

        const resultHttpRequest = new HttpRequest();
        resultHttpRequest.uri = requestUri;
        resultHttpRequest.method = requestMethod;
        resultHttpRequest.headers.addAll(httpHeaders);
        resultHttpRequest.body = requestBody;

        simletTester.httpRequest = resultHttpRequest;
      }
    },
    [
      simulator,
      requestMethod,
      requestUri,
      httpHeaders,
      requestBody,
      simletTester.simulator,
      simletTester.httpRequest,
      simletTester.response
    ]
  );

  const addHeaderButton = (smallSize?: boolean) => {
    return (
      <Tooltip title={"Add a header"} arrow wa-act="act_add_header">
        <IconButton // Button size="small"
          size={smallSize ? "small" : "medium"}
          onClick={() => {
            // Don't use array.push - the result from adding the element
            // must create a new array, which is then passed on to the hook
            const newHttpHeaders = httpHeaders.concat(new HttpHeader("", ""));
            setHttpHeaders(newHttpHeaders);
          }}
        >
          <PlusCircle style={{ color: "#238441", }} />
        </IconButton>
      </Tooltip>
    )
  }
  const deleteHeaderButton = (index: number) => {
    return (
      <Tooltip title={"Remove this header"} arrow wa-act="act_del_header">
        <IconButton // Button size="small"
          color="secondary"
          // size="small"
          onClick={() => {
            // Don't use array.splice - the result from the element removal
            // must create a new array, which is then passed on to the hook
            const newHttpHeaders = httpHeaders.filter((header, headerIndex) => headerIndex !== index);
            setHttpHeaders(newHttpHeaders);
          }}
        >
          <DeleteForever />
        </IconButton>
      </Tooltip>
    )
  }
  const headerItems = httpHeaders.map((header, index) => {
    const idHeaderName = "header-name-" + index;
    const idHeaderValue = "header-value-" + index;
    return (
      <Grid container item key={index}
        spacing={2}
      >
        { /* <Grid item xs>{" "}</Grid> */}
        <Grid container item xs={2} style={{ paddingTop: 0 }}>
          <OutlinedTextField
            id={idHeaderName}
            classes={{
              // focused: classes.focused
            }}
            fullWidth
            required
            placeholder="Header Name"
            value={header.name}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const newName: string = event.target.value;
              const newHeaders: HttpHeader[] = httpHeaders.map((oldHeader, oldHeaderIndex) => {
                return oldHeaderIndex !== index ? oldHeader : new HttpHeader(newName, oldHeader.value);
              });
              setHttpHeaders(newHeaders);
            }}
            margin="dense"
          />
        </Grid>
        <Grid container item xs={6} style={{ paddingTop: 0 }}>
          <div style={{ display: "inline-flex", width: "100%" }}>
            <OutlinedTextField
              id={idHeaderValue}
              fullWidth
              margin="dense"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const newValue: string = event.target.value;
                const newHeaders = httpHeaders.map((oldHeader, oldHeaderIndex) => {
                  return oldHeaderIndex !== index ? oldHeader : new HttpHeader(oldHeader.name, newValue);
                });
                setHttpHeaders(newHeaders);
              }}
              placeholder="Header Value"
              value={header.value}
            />
            {(httpHeaders.length > 0) && deleteHeaderButton(index)}
            {((httpHeaders.length - 1) === index) && addHeaderButton()}
          </div>
        </Grid>
        <Grid item xs>{" "}</Grid>
      </Grid >
    )
  });

  const [isSimInProgress, setIsSimInProgress] = React.useState(false);
  const cancelControllerRef = React.useRef<AbortController>(new AbortController());
  const cancellableSimPromiseRef = React.useRef<CancellablePromise<HttpResponse | string>>();

  React.useEffect(
    () => {
      // Code that gets executed after rendering the component
      ;

      // In the optionally returned function - code that gets
      // executed on component unmounting. Do cleanup in it
      return () => {
        // Abort the request first...
        cancelControllerRef.current.abort()
        // ...then cancel the promise
        cancellableSimPromiseRef.current?.cancel()
      }
    },
    []
  );

  const handleClickSimulate = () => {
    if (!simulator) {
      props.setStatusSnackbar({
        open: true,
        status: 'error',
        message: "Value for Simulator is required!"
      });
      return;
    }

    const httpRequest: HttpRequest = new HttpRequest();
    httpRequest.method = requestMethod;
    httpRequest.uri = requestUri;
    httpRequest.headers.addAll(httpHeaders);
    httpRequest.body = requestBody;

    setIsSimInProgress(true);

    // Clean up any text in the HTTP response box
    simletTester.response = "";

    const simPromise = simulate(cancelControllerRef.current, simulator, httpRequest);
    cancellableSimPromiseRef.current = makeCancellable(simPromise);
    cancellableSimPromiseRef.current.promise
      .then((httpResponse: HttpResponse | string) => {
        if (typeof httpResponse === "string") {
          props.setStatusSnackbar({
            open: true,
            status: 'error',
            message: httpResponse
          })
        }
        const responseString = (
          httpResponse instanceof HttpResponse
            ? httpResponse.toString()
            : httpResponse
        );
        simletTester.response = responseString;
      })
      .catch(({ isCanceled, ...error }) => {
        // Having the catch block supresses this error in the console
        // when the promise is cancelled:
        // "Uncaught(in promise) { isCanceled: true }"
        ; // Nothing to do
        console.log("isCanceled=" + isCanceled)
        console.log("error =" + error)
      })
      .finally(() => {
        // Initialize for another potential cancelation
        if (cancelControllerRef.current.signal.aborted) {
          cancelControllerRef.current = new AbortController();
        }

        // Don't call setIsSimInProgress to set state if the promise 
        // has been cancelled or it will otherwise cause "Can't perform a
        // React state update on an unmounted component. This is a no-op, 
        // but it indicates a memory leak in your application." upon 
        // navigating away while the fetch call is in progress
        if (!cancellableSimPromiseRef.current?.isCanceled()) {
          setIsSimInProgress(false);
        }
      });
  };

  const handleClickCancel = () => {
    cancelControllerRef.current.abort();
    // Initialize for another potential cancelation
    cancelControllerRef.current = new AbortController();
  }

  return (
    <Page title="Simlet Tester"
      infoContent={
        <div>
          <Box>
            Build HTTP Requests and submit them to an API Simulator to test your Simlets
          </Box>
        </div>
      }
    >
      <div
        // The minimal width is the same as for a Simlet Editor
        style={{ minWidth: "780px" }}
      >
        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            <Typography
              align="left"
              component="span"
              variant="h6"
            >
              HTTP Request
            </Typography>
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        <Grid container
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={2}>
            <Autocomplete
              id="request-http-method"
              autoHighlight   // highlights the first option automatically
              defaultValue={requestMethod}
              freeSolo        // allow values not in the list of options 
              forcePopupIcon  // shows an icon (a triangle) denoting there's a drop-down list
              includeInputInList
              noOptionsText="Method not found"
              fullWidth       //  take up the full width of its container
              value={requestMethod}
              onInputChange={(event: any, newInputValue: string) => {
                setRequestMethod(newInputValue);
              }}
              options={[
                "GET", "POST", "PUT", "DELETE", "OPTIONS", "PATCH", "HEAD"
              ]}
              renderInput={(params) => (
                <OutlinedTextField {...params}
                  label="HTTP Method"
                  required
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Grid container item xs={6}>
            <OutlinedTextField
              id="request-uri"
              fullWidth
              label="URI"
              margin="dense"
              placeholder="/path?query"
              required
              value={requestUri}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setRequestUri(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid item xs={2}>
            <Typography
              align="left"
              style={{
                fontWeight: "bold",
                marginLeft: "12px",
              }}
            >
              Header Name
          </Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%"
            }}>
              <Typography
                align="left"
                style={{
                  fontWeight: "bold",
                  marginLeft: "12px",
                }}
              >
                Header Value
            </Typography>
              <div style={{ alignContent: "right" }}>
                {(0 === httpHeaders.length) && addHeaderButton(true)}
              </div>
            </div>
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        {headerItems}
        <Grid container item
          spacing={2}
          style={{ marginTop: "12px" }}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            <OutlinedMultilineTextField
              id="request-body"
              fullWidth
              label="Body"
              rows={12}
              value={requestBody}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setRequestBody(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>

        <Grid container
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              {
                // A value must be selected from the list of simulators because
                // the calls to test a simlet are proxied to avoid CORS issues
              }
              <SimulatorsDropdown
                simulator={simulator}
                setSimulator={setSimulator}
                id="simulatorUrl"
                fullWidth
                label="Simulator"
                margin="dense"
                required
                {...props}
              />
              {
            // FUTURE: Allow the list to be refreshed no more than once every X seconds.
            // Look into using https://reactjs.org/docs/faq-functions.html#debounce
            /*
            <Tooltip title={"Reload list"} arrow>
              <Button
                // disabled
                size="small"
                onClick={() => {
                }}
              >
                <RefreshCircle style={{ color: "#238441", }} />
              </Button >
            </Tooltip>
            */ }
            </div>
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>

        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8} justify="flex-end">
            { /*
          //justify="space-between">
          <div>
            <ContainedPrimaryButton
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => history.push(AppRoutes.DslEditor)}
            >
              {"Back"}
            </ContainedPrimaryButton>
          </div>
          */ }
            {
              // The relative position in 'style' is to overlay the
              // the action button with a cancel button displayed as
              // CircularProgress spinner.
              // Keep timeout=0; transitionDelay determines when the
              // cancel button is rendered and user can click on it.
              // Set it to a value to prevent double clicks to trigger
              // the action and to cancel it right away.
              // The "div" with style 'display: "block"' seems to be
              // required for this to work with material-ui core v4.11.2
            }
            <div style={{ position: 'relative' }}>
              <StyledButton
                wa-act="act_run_simlet_test"
                color="primary"
                disabled={isSimInProgress}
                size="medium"
                variant="contained"
                onClick={handleClickSimulate}
              >
                {/*"Simulate"*/}
                {"Run It"}
              </StyledButton>
              <Zoom
                in={isSimInProgress}
                style={{
                  transitionDelay: isSimInProgress ? '500ms' : '0ms',
                }}
                timeout={0}
                unmountOnExit
              >
                <div style={{ display: "block" }}>
                  <Tooltip title="Cancel">
                    <Button
                      className={classes.buttonProgress}
                      size="medium"
                      onClick={handleClickCancel}
                    >
                      <CircularProgress
                        color="inherit"
                        size={24}
                        thickness={8}
                        variant="indeterminate"
                      />
                    </Button>
                  </Tooltip>
                </div>
              </Zoom>
            </div>
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            {
              // For some reason, the HR divider doesn't get displayed without
              // adding "width: 100%" style even though variant="fullWidth"
            }
            <Divider
              component="hr"
              orientation="horizontal"
              style={{ width: "100%" }}
              variant="fullWidth"
            />
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            <Typography
              align="left"
              component="span"
              variant="h6"
            >
              HTTP Response
            </Typography>
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
        <Grid container item
          spacing={2}
        >
          { /* <Grid item xs>{" "}</Grid> */}
          <Grid container item xs={8}>
            <OutlinedMultilineTextField
              id="http-response"
              fullWidth
              label="Raw Response"
              InputProps={{
                readOnly: true,
              }}
              rows={16}
              value={simletTester.response}
            />
          </Grid>
          <Grid item xs>{" "}</Grid>
        </Grid>
      </div>
    </Page >
  );
}
