import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';

// The original ".MuiDrawer-paper" class defines "top: 0". That
// causes the drawer to start from the top of the page, which 
// then requires qorkaronds, like adding <Toolbar /> element
export const ClippedDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    top: auto
  },
`

export default ClippedDrawer;
