import env from '../react-runtime-env';

const ORCHESTRATOR_URL =
  env('REACT_APP_ORCHESTRATOR_URL')
  ||
  process.env.REACT_APP_ORCHESTRATOR_URL
  ||
  window.location.origin;

export const orchestratorUrl = (): string => {
  // console.log(process.env)
  if (ORCHESTRATOR_URL) {
    return ORCHESTRATOR_URL;
  }
  throw new Error("REACT_APP_ORCHESTRATOR_URL not defined!");
}


const ADMIN_PROXY_PATH = "/api/v1/orgs/{org}/proxy/admin/simulators/{simulatorId}";
export const adminProxyUrl = (org: string, simulatorId: string): string => {
  const adminProxyPath = ADMIN_PROXY_PATH.replace("{org}", org)
    .replace("{simulatorId}", simulatorId);
  const url = orchestratorUrl() + adminProxyPath;
  return url;
}


const SIMULATOR_PROXY_URL = "/api/v1/orgs/{org}/proxy/simulators/{simulatorId}";

export const simulatorProxyUrl = (org: string, simulatorId: string): string => {
  const simulatorProxyPath = SIMULATOR_PROXY_URL.replace("{org}", org)
    .replace("{simulatorId}", simulatorId);
  const url = orchestratorUrl() + simulatorProxyPath;
  return url;
}

