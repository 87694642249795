import React from 'react';
import {
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import clsx from 'clsx';
// import { Divider } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ApiIcon from 'mdi-material-ui/Api';
import EditDocumentIcon from 'mdi-material-ui/FileDocumentEditOutline';
import InfoIcon from 'mdi-material-ui/InformationVariant';
import PlayPauseIcon from 'mdi-material-ui/PlayPause';
import TestTubeIcon from 'mdi-material-ui/TestTube';
import ClippedDrawer from './shared/ClippedDrawer';
import AppRoutes from '../modules/routes';
import useStyles from '../modules/styles';
import About from './About';
import DslEditor from './DslEditor';
import SimletTester from './SimletTester';
import Simulations from './Simulations';
import Simulators from './Simulators';

// const TabPanel = React.memo((props: any) => {
const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  // Instead of initializing tab panels on the first call regadless if
  // they are selected (value === index) or not, their initialization
  // happens when the respective tab is selected. That achieves this:
  // * Simulators get loaded when the user visits the pane. That gets
  //   a better user experience in case of any errors during loading
  //   (e.g. timeout) - it is clear why they are happening unstead of
  //   seeing a popup for an error unrelated to the current tab pane.
  // * The DSL Editor content would not be displayed properly if the
  //   editor were initialized but hidden by hiding its wrapping div
  //   (the one below with role="tabpanel").
  const [content, setContent] = React.useState<React.ReactFragment | null>(null);
  React.useEffect(
    () => {
      if (value === index && !content) {
        setContent(children);
      }
    },
    [value, index, content, children]
  );

  if (!content) {
    return <></>;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        height: "100%",
      }}
      {...other}
    >
      { /* { children} */}
      { content}
    </div>
  )
};

const MenuTab = withStyles((theme) => ({
  // root: {
  //   maxWidth: "320px",
  //   minWidth: "264px",
  // },
  // Settings to inline icon and label vs. the default icon above label
  wrapper: {
    alignItems: "normal",
    flexDirection: "row",
    justifyContent: "left",
    "& > svg": {
      marginRight: "8px"
    },
  },
  "labelIcon": {
    minHeight: "56px",
  },
  "textColorInherit": {
    backgroundColor: "inherit",
    opacity: 1,
    "&.Mui-selected": {
      backgroundColor: "#f7f7f7",
      opacity: 1,
    },
    "&.Mui-disabled": {
      backgroundColor: "#f7f7f7",
      opacity: 1,
    }
  },
}))((props: any) => <Tab {...props} />);

const MenuTabs = withStyles((theme) => {
  return {
    indicator: {
      backgroundColor: '#238441',
    },
  }
})(Tabs);


function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Home = (props: any) => {
  const classes = useStyles();

  // FUTURE TODO Open/Close the Drawer
  const [open,
    //setOpen
  ] = React.useState(true); //false);

  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const urlPath = location.pathname;
  React.useEffect(
    () => {
      var initialValue;
      if (AppRoutes.Simulators === urlPath) {
        initialValue = 0;
      } else if (AppRoutes.Simulations === urlPath) {
        initialValue = 1;
      } else if (AppRoutes.DslEditor === urlPath) {
        initialValue = 2;
      } else if (AppRoutes.SimletTester === urlPath) {
        initialValue = 3;
      } else {
        initialValue = 4;
      }
      setValue(initialValue);
    },
    [urlPath]
  );

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 1:
        history.push(AppRoutes.Simulations);
        break;

      case 2:
        history.push(AppRoutes.DslEditor);
        break;

      case 3:
        history.push(AppRoutes.SimletTester);
        break;

      case 4:
        history.push(AppRoutes.About);
        break;

      default:
      case 0:
        history.push(AppRoutes.Simulators);
        break;
    }

    // Don't set the new value: doing so breaks the Prompt functionality
    // as the tab view is changed even when the user cancels the action
    // setValue(newValue);
  };

  return (
    <div
      style={{
        // The AppBar in the Header is 64px high
        height: "calc(100% - 64px)",
        // Only the content scrolls and has scrollbar, not the appBar header
        // This works in tandem with "overflow-y: hidden" for the whole body
        overflowY: "auto",
      }}
    >
      { /* <Header /> */}
      <ClippedDrawer
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
      >
        <div className={classes.drawerContainer}>
          <MenuTabs
            // centered={false}
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
          // aria-label="Vertical tabs example"
          // className={classes.tabs}
          >
            {
              // For inline display of the icon and text, see
              // our custom MenuItem, MenuItemIcon, MenuItemText
            }
            <MenuTab
              icon={<PlayPauseIcon />}
              label="Simulators"
              disableRipple
              value={0}
              disabled={value === 0}
              {...a11yProps(0)}
            />
            <MenuTab
              icon={<ApiIcon />}
              label="Simulations"
              disableRipple
              value={1}
              disabled={value === 1}
              {...a11yProps(1)}
            />
            <MenuTab
              icon={<EditDocumentIcon />}
              label="Simlet Editor"
              disableRipple
              value={2}
              disabled={value === 2}
              {...a11yProps(2)}
            />
            <MenuTab
              icon={<TestTubeIcon />}
              label="Simlet Tester"
              disableRipple
              value={3}
              disabled={value === 3}
              {...a11yProps(3)}
            />
            { /* Adding a non-Tab element causes problems
            <Divider />
            */ }
            <MenuTab
              icon={<InfoIcon />}
              label="About"
              disableRipple
              value={4}
              disabled={value === 4}
              {...a11yProps(4)}
            />
          </MenuTabs>
        </div>
      </ClippedDrawer>
      <main
        className={clsx(classes.content,
          { [classes.contentShift]: open, }
        )}
        style={{
          height: "100%",
        }}
      >
        <TabPanel value={value} index={0}>
          <Simulators {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Simulations {...props} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DslEditor {...props} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SimletTester {...props} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <About {...props} />
        </TabPanel>
        {
          /* 
          'Switch' with the fallback Route allow for directing the user to 
          the fallback route when the route doesn't match any of those before
          it and also to refresh/reload the page and stay at the same route.
          */
        }
        { /*
        <Switch>
          <Route
            path={AppRoutes.Simulators}
            render={() => (
              <Simulators {...props} />
            )}
          />
          <Route
            path={AppRoutes.Simulations}
            render={() => (
              <Simulations {...props} />
            )}
          />
          <Route
            path={AppRoutes.DslEditor}
            render={() => (
              <DslEditor {...props} />
            )}
          />
          <Route
            path={AppRoutes.SimletTester}
            render={() => (
              <SimletTester {...props} />
            )}
          />
          <Route
            path={AppRoutes.About}
            render={() => (
              <About {...props} />
            )}
          />
          { 
            // Fallback route - when none above are a match
          }
          <Route render={() => <Redirect to={AppRoutes.Fallback} />} />
        </Switch>
        */ }
      </main>
    </div >
  );
}

export default withRouter(Home);
