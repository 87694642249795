import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OutlinedTextField from './shared/OutlinedTextFields';
import { CancellablePromise, makeCancellable } from '../modules/cancellablePromise';
import {
  loadSimulators,
  // mockLoadSimulators,
  SimulatorSpec,
  useSimulators
} from '../modules/simulators';


export default function SimulatorsDropdown(props: any) {
  const { simulators } = useSimulators();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<SimulatorSpec[]>(simulators.list);

  const loading = open && (options.length === 0);

  const cancelControllerRef = React.useRef<AbortController>(new AbortController());
  const cancellableLoadingPromiseRef =
    React.useRef<CancellablePromise<SimulatorSpec[]>>();

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    cancelControllerRef.current = new AbortController();

    const loadingPromise = loadSimulators(cancelControllerRef.current);
    cancellableLoadingPromiseRef.current = makeCancellable(loadingPromise);
    cancellableLoadingPromiseRef.current.promise
      .then((simulatorSpecs: SimulatorSpec[]) => {
        setOptions(simulatorSpecs)
        simulators.list = simulatorSpecs;
      })
      .catch((error) => {
        // This code gets executed also upon canceling in the useRef cleanup code
        if (error.isCanceled) {
          return; // Do nothing
        }

        // Close the dropdown in case of an error
        setOpen(false);

        // if (error instanceof DOMException && error.message === "Aborted") {
        //   console.log("Got a DOMException for Aborted fetch. Not displaying an error");
        //   return;
        // }

        const errMsg = (
          error instanceof Error
            ? error.message
            : "" + error
        );
        props.setStatusSnackbar({
          open: true,
          status: 'error',
          message: errMsg,
        });
      })
      .finally(() => {
      })

    // In the optionally returned function, code that gets executed
    // on component unmounting, including upon closing the dropdown
    // or moving away from the view/page. Do cleanup in it
    return () => {
      // Abort the request first...
      cancelControllerRef.current.abort();
      // ...then cancel the promise to avoid memory leaks
      cancellableLoadingPromiseRef.current?.cancel();
    }
  },
    // react-hooks/exhaustive-deps
    // eslint-disable-next-line
    [
      loading,
      simulators.list,
    ]
  );

  // Remove custom props passed down from the parent that are not recognized
  // and not valid for an Autocomplete element. This fixes warnings like this one:
  // "Warning: React does not recognize the `setSimulator` prop on a DOM element"
  const {
    setSimulator,
    setStatusSnackbar,
    staticContext,
    ...autocompleteProps
  } = props;

  return (
    <Autocomplete
      {...autocompleteProps}
      defaultValue={props.simulator}
      value={props.simulator}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionDisabled={(option: SimulatorSpec) =>
        option.state !== "running"
      }
      getOptionSelected={(option: SimulatorSpec, value: SimulatorSpec) =>
        option.url === value.url
      }
      getOptionLabel={(option: SimulatorSpec) => option?.name || ""}
      options={options}
      loading={loading}
      renderOption={(simulator: SimulatorSpec) => (
        <React.Fragment>
          <Paper
            // className={classes.paper}
            // elevation={1}
            style={{
              backgroundColor: "unset",
              padding: "4px 4px 4px 4px",
              width: "100%",
            }}
            square
            variant="outlined"
          >
            <Grid
              container
              direction="column"
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
              >
                <Grid item>
                  {"Simulator:"}
                </Grid>
                <Grid item>
                  &nbsp;
                </Grid>
                <Grid item>
                  {simulator.name}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-start"
              >
                <Grid item>
                  {"at"}
                </Grid>
                <Grid item>
                  &nbsp;
                </Grid>
                <Grid item>
                  {simulator.url}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-start"
              >
                <Grid item>
                  {"Simulation:"}
                </Grid>
                <Grid item>
                  {" "}
                </Grid>
                <Grid item>
                  {
                    simulator && simulator.apisims && simulator.apisims.length > 0
                      ? simulator.apisims[0].name
                      : "None"
                  }
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
              >
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {simulator.state}
                  </Typography>
                </Grid>
                <Grid item>
                  v{simulator.version}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </ React.Fragment>
      )}
      renderInput={(params) => (
        <OutlinedTextField
          {...params}
          label={props.label}
          margin={props.margin}
          // Doesn't work? readOnly={props.readOnly}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      // The newValue argument here is the whole object, not just
      // the input string whereas it is the string in onInputChange
      onChange={(event: any, newValue: SimulatorSpec) => {
        props.setSimulator(newValue)
      }}
    />
  )
}
