import React from 'react';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import CheckIcon from 'mdi-material-ui/Check';
import Page from './shared/Page';

const Accordion = withStyles({
  root: {
    '&$expanded': {
      margin: "8px 0px",
      minHeight: "56px",
    },
  },
  expanded: {},
})(MuiAccordion);

// Customized so when expanded no margins get added to the summary section
const AccordionSummary = withStyles({
  content: {
    '&$expanded': {
      margin: "12px 0px",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const FaqElement = (props: any) => {
  const { question, answer } = props;
  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          aria-label="Expand"
          aria-controls="faq"
        >
          <Typography variant="h6">
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </React.Fragment >
  )
}

const BenefitItem = (props: any) => {
  return (
    <ListItem
      disableGutters
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
      }}
    >
      <ListItemIcon style={{ minWidth: "32px" }}>
        <CheckIcon />
      </ListItemIcon>
      <ListItemText>
        {props.children}
      </ListItemText>
    </ListItem>
  )
}


const About = (props: any) => {
  return (
    <Page title="About">
      <Typography variant="body1">
        Welcome to the show! Well...more like of a preview of what's coming.
        Pardon the construction and all the dust, and things not being fully
        functional. We are getting there...
      </Typography>
      <br />
      <Typography variant="body1">
        <b>API Simulator Cloud</b>, by the creators of the <Link
          href="https://apisimulator.io"
          target="_blank"
          rel="noopener"
          underline="none"
        >
          API Simulator
        </Link>, will be the platform for you to:
      </Typography>
      <List
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "16px",
        }}
      >
        <BenefitItem>
          Provision your own API Simulators hosted by us.
          </BenefitItem>
        <BenefitItem>
          Model API simulations and their simlets.
          </BenefitItem>
        <BenefitItem>
          Deploy simlets to running API simulations.
          </BenefitItem>
        <BenefitItem>
          Test deployed simlets with real requests.
          </BenefitItem>
        <BenefitItem>
          Enable and elevate your development and testing efforts with
          your API simulations accessible at a unique domain per Simulator.
          </BenefitItem>
      </List>
      <Typography variant="body1">
        ...all with ease and from the convenience of this API Simulator Cloud
        Studio application.
      </Typography>
      <br />
      <Typography variant="body1">
        Frankly, we can use <i>your help</i> to shape up API Simulator Cloud!
        Poke around,
        explore the demo Simulators,
        model simlets with the Simlet Editor (hint: you can download them for
        use with your own copy of the API Simulator),
        simulate a request or two using the Simlet Tester against the simulation
        running in the Demo simulator.
        Isn't that fun?
      </Typography>
      <br />
      <Typography variant="body1">
        Check out the FAQs below. Don't hesitate to reach out if you
        still have questions.
      </Typography>
      <br />
      <Typography variant="body1">
        We would love to hear what you think - any feedback is welcome and
        very much appreciated! Just use the chat widget below or send us an
        email to <a
          href="mailto:contact@apisimulator.io?subject=API Simulator Cloud Feedback"
        >
          contact@apisimulator.io
        </a>
      </Typography>
      <Typography variant="body1">
        <br />
        Happy API Simulating!
      </Typography>
      <Typography variant="h6"
        style={{
          marginTop: "32px",
          fontWeight: "bold",
        }}
      >
        FAQs
      </Typography>
      <FaqElement
        question={"Will the API Simulator still be free?"}
        answer={
          <>Yes! The&nbsp;
            <Link
              href="https://apisimulator.io"
              target="_blank"
              rel="noopener"
              underline="none"
            >
              API Simulator
          </Link>
          &nbsp;will be free to download and use for your own
          needs as it has always been.</>
        }
        {...props}
      />
      <FaqElement
        question={"Will there be an API Simulator Cloud free tier?"}
        answer={"Yes, the plan is to offer a free tier. Stay tuned for details."}
        {...props}
      />
      <FaqElement
        question={
          <>I use the API Simulator as dependency stand-in in load tests.
          Will API Simulator Cloud support that?</>
        }
        answer={
          <>Yes - the plan is to offer a tier with higher or even removed
          throttling limits.
          After all, this is one of the great Use Cases for using the
          API Simulator in the first place.</>
        }
        {...props}
      />
      <FaqElement
        question={"How can I sign up to be an early adopter?"}
        answer={
          <>Send us an email to&nbsp;
            <Link
              href="mailto:contact@apisimulator.io?subject=API Simulator Cloud Early Adopter"
              underline="none"
            >
              contact@apisimulator.io
          </Link> or a chat message even if we are offline.</>
        }
        {...props}
      />
      <FaqElement
        question={"Will API Simulator Cloud replace the free API Simlet Editor?"}
        answer={
          <>Yes. API Simulator Cloud will offer the same API Simlet Editor in an
          integrated package. Still, the Simlet Editor can be used on its own to
          model simlets and to download them - for free and without any strings
          attached.</>
        }
        {...props}
      />
      <FaqElement
        question={"Who owns the simlets I model in API Simulator Cloud Studio?"}
        answer={"You do - your simlets are your simlets. Always."}
        {...props}
      />
      <FaqElement
        question={"Do you plan on housing community-maintained API simulations?"}
        answer={
          <>Yes. At some point API Simulator Cloud may house
          community-maintained API simulations. It will be totally up
          to you to contribute and participate if you want.</>
        }
        {...props}
      />


      < Box style={{ marginBottom: "16px" }} />
    </Page >
  )
}

export default About;
