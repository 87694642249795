import React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from 'mdi-material-ui/DeleteForever';
import DotsVerticalIcon from 'mdi-material-ui/DotsVertical';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import PlayIcon from 'mdi-material-ui/Play';
import PlusIcon from 'mdi-material-ui/Plus';
import ResizeIcon from 'mdi-material-ui/Resize';
import StopIcon from 'mdi-material-ui/Stop';
import { CancellablePromise, makeCancellable } from '../modules/cancellablePromise';
import {
  loadSimulators,
  // mockLoadSimulators,
  SimulatorSpec,
  useSimulators
} from '../modules/simulators';
import useStyles from '../modules/styles';
import { MenuItemIcon, MenuItemText } from './shared/MenuItems';
import Page from './shared/Page';
import SimulationsTree from './shared/SimulationsTree';
import SimulatorStateAvatar from './shared/SimulatorStateAvatar';
import StyledButton from './shared/StyledButtons';

// Customized so when expanded no margins get added to the summary section
const AccordionSummary = withStyles({
  content: {
    '&$expanded': {
      margin: "12px 0px",
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const SimulatorActionsMenu = (props: any) => {
  const classes = useStyles();

  const simulators = props.simulators;

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement[]>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.stopPropagation()

    const anchors: HTMLElement[] = new Array(simulators.list.length)
    anchors[index] = event.currentTarget
    setAnchorEl(anchors)

    setOpen((prevOpen) => !prevOpen)
  };

  const handleClose = (event: React.MouseEvent<EventTarget>, index: number) => {
    event.stopPropagation()
    event.preventDefault()

    if (anchorEl && anchorEl[index]?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab' || event.key === 'Escape') {
      // Don't move focus, keep it on the button (when Tab key is pressed)
      event.preventDefault();
      // Close the popup menu
      setOpen(false);
    }
  }

  const handleStart =
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      event.stopPropagation()
      props.setStatusSnackbar({
        open: true,
        status: 'info',
        message: "Coming soon (Start Simulator named " + simulators.list[index].name + ")"
      })
    }
  const handleStop =
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      event.stopPropagation()
      props.setStatusSnackbar({
        open: true,
        status: 'info',
        message: "Coming soon (Stop Simulator named " + simulators.list[index].name + ")"
      })
    }
  const handleResize =
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      event.stopPropagation()
      props.setStatusSnackbar({
        open: true,
        status: 'info',
        message: "Coming soon (Resize Simulator named " + simulators.list[index].name + ")"
      })
    }
  const handleDestroy =
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      event.stopPropagation()
      props.setStatusSnackbar({
        open: true,
        status: 'info',
        message: "Coming soon (Destroy Simulator named " + simulators.list[index].name + ")"
      })
    }

  const index = props.simulatorIndex;

  return (
    <React.Fragment>
      <IconButton
        aria-haspopup="true"
        aria-label="more actions"
        onClick={(event) => {
          handleToggle(event, index)
        }}
      >
        <DotsVerticalIcon />
      </IconButton>
      <Popper
        id={"moreAtionsMenu" + index}
        anchorEl={anchorEl && anchorEl[index]}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        open={open}
        placement="bottom"
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom'
                  ? 'center top'
                  : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => handleClose(event, index)}
              >
                <Paper
                  className={classes.popperRoot}
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "currentColor",
                  }}
                >
                  { // arrow && 
                    (
                      <span
                        className={classes.popperArrow}
                        ref={setArrowRef}
                        style={{ color: "#FFFFFF" }}
                      />
                    )
                  }
                  <MenuList
                    id={"moreActionsMenuList" + index}
                    // autoFocusItem={open}
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      wa-act="act_start_simulator"
                      disabled={simulators.list[index].isRunning()}
                      onClick={(event) => handleStart(event, index)}
                    >
                      <MenuItemIcon>
                        <PlayIcon fontSize="small" />
                      </MenuItemIcon>
                      <MenuItemText primary="Start" />
                    </MenuItem>
                    <MenuItem
                      wa-act="act_stop_simulator"
                      disabled={!simulators.list[index].isRunning()}
                      onClick={(event) => handleStop(event, index)}
                    >
                      <MenuItemIcon>
                        <StopIcon fontSize="small" />
                      </MenuItemIcon>
                      <MenuItemText primary="Stop" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      wa-act="act_resize_simulator"
                      disabled={simulators.list[index].isRunning()}
                      onClick={(event) => handleResize(event, index)}
                    >
                      <MenuItemIcon>
                        <ResizeIcon fontSize="small" />
                      </MenuItemIcon>
                      <MenuItemText primary="Resize" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      wa-act="act_destroy_simulator"
                      disabled={simulators.list[index].isRunning()}
                      onClick={(event) => handleDestroy(event, index)}
                    >
                      <MenuItemIcon>
                        <DeleteIcon fontSize="small" />
                      </MenuItemIcon>
                      <MenuItemText primary="Destroy" />
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}


export default function Simulators(props: any) {
  const classes = useStyles();
  const { simulators, setSimulators } = useSimulators();

  const [loading, setLoading] = React.useState(false);
  const cancelControllerRef = React.useRef<AbortController>(new AbortController());
  const cancellableLoadingPromiseRef =
    React.useRef<CancellablePromise<SimulatorSpec[]>>();

  React.useEffect(
    () => {
      if (!loading && simulators.list.length === 0) {
        setLoading(true);

        const loadingPromise = loadSimulators(cancelControllerRef.current);
        cancellableLoadingPromiseRef.current = makeCancellable(loadingPromise);
        cancellableLoadingPromiseRef.current.promise
          .then((simulatorSpecs: SimulatorSpec[]) => {
            setSimulators(simulatorSpecs)
          })
          .catch((error) => {
            if (error.isCanceled) {
              return; // Do nothing
            }

            const errMsg = (
              error instanceof Error
                ? error.message
                : "" + error
            );
            props.setStatusSnackbar({
              open: true,
              status: 'error',
              message: errMsg,
            });
          })
          .finally(() => {
            // Initialize for another potential cancelation
            if (cancelControllerRef.current.signal.aborted) {
              cancelControllerRef.current = new AbortController();
            }

            // Don't call setLoading to set state if the promise
            // has been cancelled or it will otherwise cause "Can't perform a
            // React state update on an unmounted component. This is a no-op, 
            // but it indicates a memory leak in your application." upon 
            // navigating away while the fetch call is in progress
            if (!cancellableLoadingPromiseRef.current?.isCanceled()) {
              setLoading(false);
            }
          });
      }

      // In the optionally returned function - code that gets
      // executed on component unmounting. Do cleanup in it
      return () => {
        // Abort the request first...
        cancelControllerRef.current.abort();
        // ...then cancel the promise to avoid memory leaks
        cancellableLoadingPromiseRef.current?.cancel();
      }
    },
    // react-hooks/exhaustive-deps asks for including `loading` as a
    // dependency but including it leads to a never ending loading
    // eslint-disable-next-line
    [
      // loading,
      // simulators.list.length,
      // setSimulators,
      // props,
    ]
  );

  // Currently, the user cannot cancel the loading
  // const handleClickCancelLoading = () => {
  //   cancelControllerRef.current.abort();
  //   // Initialize for another potential cancelation
  //   cancelControllerRef.current = new AbortController();
  // }

  return (
    <Page
      title="Manage Simulators"
      // infoContent={<SomeReactComponent />}
      infoContent={"Create, destroy, view, start, stop your API Simulators"}
    >
      {
        loading ? (
          <div style={{ display: "inline-flex" }}>
            <Zoom
              in={loading}
              style={{
                transitionDelay: loading ? '500ms' : '0ms',
              }}
              timeout={0}
              unmountOnExit
            >
              <CircularProgress
                size={24}
                classes={{
                  colorPrimary: classes.circularProgressColor,
                }}
                thickness={6}
                variant="indeterminate"
              />
            </Zoom>
            <div>{"Loading..."}</div>
          </div>
        ) : (
            <div>
              <Box
                style={{
                  paddingBottom: "16px",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <StyledButton
                  wa-act="act_create_simulator"
                  onClick={() =>
                    props.setStatusSnackbar({
                      open: true,
                      status: 'info',
                      message: "Coming soon (Create a Simulator)"
                    })
                  }
                >
                  <PlusIcon />
                  {"Create Simulator"}
                </StyledButton>
              </Box>
              {simulators.list.map((simulator: SimulatorSpec, index: number) => {
                return (
                  // The "key" is required by React to uniquely id an element in the list
                  <div key={simulator.id}
                    style={{
                      // For separation between the accordion elements
                      paddingBottom: "16px",
                    }}
                  >
                    <Accordion defaultExpanded={false}>
                      <AccordionSummary
                        id={"panel-header" + index}
                        aria-controls={"panel-content" + index}
                        className={classes.accordionSummaryExpanded}
                        expandIcon={<ChevronDownIcon />}
                      // style={{
                      //   // To display the expandIcon on the left side
                      //   flexDirection: "row-reverse"
                      // }}
                      >
                        <Box textAlign="left" width="100%"
                          style={{
                            display: "inline-flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <Box>
                            <Box
                              style={{
                                // These help with the verticle alignment to be centered
                                display: "inline-flex",
                                contain: "content",
                              }}
                            >
                              <SimulatorStateAvatar
                                simulator={simulator}
                                {...props}
                              />
                            </Box>
                            <Box style={{ display: "inline-block", paddingLeft: 10 }}>
                              <div>
                                <Typography
                                  variant="h5"
                                >
                                  {simulator.name}
                                </Typography>
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                <Typography
                                  display="inline"
                                  variant="subtitle2"
                                >
                                  { /* {simulator.cpu}{" CPU"}{" | "} */}
                                  {simulator.memory}{" | "}
                                v{simulator.version}{" |"}
                                </Typography>
                                <Typography
                                  display="inline"
                                  variant="subtitle2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  &nbsp;{simulator.state}
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          { /*
                          <Box
                            style={{
                              // These help with the verticle alignment to be centered
                              display: "inline-flex",
                              contain: "content",
                            }}
                          >
                            <SimulatorMoreActionsMenu simulatorIndex={index} {...props} />
                          </Box>
                          */ }
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          alignItems: "left",
                          display: "block"
                        }}
                      >
                        <div>
                          <div style={{ display: "inline-flex" }}>
                            <Typography style={{ fontWeight: "bold" }}>
                              {"URL"}:&nbsp;
                            </Typography>
                            <Typography>
                              {simulator.url}
                            </Typography>
                          </div>
                        </div>
                        <div>
                          <div style={{ display: "inline-flex" }}>
                            <Typography style={{ fontWeight: "bold" }}>
                              {"Simulation"}:&nbsp;
                            </Typography>
                            <SimulationsTree
                              simulator={simulator}
                              {...props}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                      {
                        <Divider />
                      }
                      <AccordionActions>
                        <SimulatorActionsMenu
                          simulators={simulators}
                          simulatorIndex={index}
                          {...props}
                        />
                      </AccordionActions>
                    </Accordion>
                  </div>
                )
              })
              }
            </div>
          )
      }
    </Page >
  );
}
