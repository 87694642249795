
export class HttpHeader {
  private _name: string;
  private _value: string;

  constructor(name: string, value: string) {
    this._name = name?.trim() || '';
    this._value = value || '';
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value?.trim() || this._name;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value || this._value;
  }

  toJSON(): object {
    return { name: this._name, value: this._value }
  }
}

export class HttpHeaders {
  private headers: Array<HttpHeader>;

  public constructor() {
    this.headers = new Array<HttpHeader>();
  }

  public add(name: string, value: string): void {
    const header = new HttpHeader(name, value);
    this.headers.push(header);
  }

  public addHeader(header: HttpHeader): void {
    if (null != header) {
      this.headers.push(header);
    }
  }

  public addAll(httpHeaders: HttpHeader[]): void {
    if (null != httpHeaders) {
      httpHeaders
        .filter((header) => header != null)
        .forEach((header) => {
          this.addHeader(header);
        });
    }
  }

  public removeAll(): void {
    while (this.count() > 0) {
      this.remove(0);
    }
  }

  public remove(index: number): HttpHeader | null {
    if (index >= 0 && index < this.count()) {
      const removedHeaders: HttpHeader[] = this.headers.splice(index, 1);
      return (null != removedHeaders ? removedHeaders[0] : null);
    }
    return null;
  }

  public count() {
    return this.headers.length;
  }

  public forEach(callback: (header: HttpHeader, index: number) => void): void {
    let _index: number = 0;
    for (const header of this.headers) {
      callback(header, _index);
      _index++;
    }
  }

  public toArray() {
    const resultHeaders: HttpHeader[] = [];
    this.forEach((header) => {
      resultHeaders.push(header);
    });
    return resultHeaders;
  }

  public toString(): string {
    let s = '';
    for (const header of this.headers) {
      s += header.name + ': ' + header.value + '\r\n'
    }
    return s;
  }
}

export class HttpMessage {
  private _headers: HttpHeaders = new HttpHeaders();
  private _body: string = '';

  get headers(): HttpHeaders {
    return this._headers;
  }

  get body(): string {
    return this._body;
  }

  set body(value: string) {
    this._body = value || '';
  }

  toString(): string {
    const headers = this._headers.toString();
    let s = headers;
    // There must be two line ends (CRLFCRLF) between the headers and the body
    if (s.length === 0) {
      s += '\r\n' // otherwise, the last header line already ends with CRLF
    }
    s += '\r\n'
    s += this._body;
    return s;
  }
}

// Returns `true` if the HTTP Method for sure
// doesn't support body, or false otherwise
export const isNoBodyHttpMethod = (method: string): boolean => {
  const httpMethod = method && method.toUpperCase();
  switch (httpMethod) {
    case "GET":
    case "HEAD":
    case "DELETE":
    case "OPTION":
      return true;

    default:
      return false;
  }
}

export class HttpRequest extends HttpMessage {
  private _httpMethod: string = '';
  private _uri: string = '';
  private _httpVersion: string = 'HTTP/1.1';

  get method(): string {
    return this._httpMethod;
  }

  set method(value: string) {
    this._httpMethod = value || '';
  }

  get uri(): string {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value || '';
  }

  get httpVersion(): string {
    return this._httpVersion;
  }

  set httpVersion(value: string) {
    if (null !== value && value.trim().length > 0) {
      this._httpVersion = value;
    }
  }

  requestLine(): string {
    return this._httpMethod + ' ' + this._uri + ' ' + this._httpVersion;
  }

  toString(): string {
    const requestLine: string = this.requestLine();
    const headersAndBody: string = super.toString();
    const request = requestLine + '\r\n' + headersAndBody;
    return request;
  }

  asJson(): object {
    const encodedUri = encodeURI(this.uri);

    // Don't include headers with empty name
    const headers: HttpHeader[] = this.headers.toArray()
      .filter((header: HttpHeader) => header?.name.trim().length > 0)

    const json = {
      method: this.method,
      version: this.httpVersion,
      uri: encodedUri,
      headers: headers,
      // ALWAYS inlude the body, if any
      // base64Body: (
      //   isNoBodyHttpMethod(this.method)
      //     ? ""
      //     : btoa(this.body)
      // )
      base64Body: btoa(this.body),
    }
    return json
  }
}

export class HttpStatus {
  public code: number = -1;
  private _reason: string = '';

  get reason() {
    return this._reason;
  }

  set reason(value: string) {
    this._reason = value || '';
  }
}


export class HttpResponse extends HttpMessage {
  status: HttpStatus = new HttpStatus();

  toString(): string {
    const responseLine: string = this.status.code + (this.status.reason ? " " + this.status.reason : "");
    const headersAndBody: string = super.toString();
    const response = responseLine + '\r\n' + headersAndBody;
    return response;
  }

}