import React from 'react';
import * as monaco from 'monaco-editor';
// import * as actions from 'monaco-editor/esm/vs/platform/actions/common/actions';
import { MenuRegistry } from 'monaco-editor/esm/vs/platform/actions/common/actions';
import { LanguageServiceDefaultsImpl } from 'monaco-yaml/lib/esm/monaco.contribution';
import { setupMode } from 'monaco-yaml/lib/esm/yamlMode';
import apisimDslSchema from '../apisim-schema.json';
import { getGlobal } from './utils';
import { MonacoEditorState } from '../modules/monacoEditorState';

// !!! 'worker-loader!' is specific to webpack !!!
// However, using worker-loader seems to be the easiest way to make this work.
// Other options like these didn't work:
// - https://github.com/GoogleChromeLabs/worker-plugin
// - https://webpack.js.org/loaders/worker-loader/#config coupled
//   with https://github.com/gsoft-inc/craco/issues/69
/* eslint import/no-webpack-loader-syntax: off */
import EditorWorker from 'worker-loader!monaco-editor/esm/vs/editor/editor.worker';
/* eslint import/no-webpack-loader-syntax: off */
import YamlWorker from 'worker-loader!monaco-yaml/lib/esm/yaml.worker';

const globals = getGlobal();
globals.MonacoEnvironment = {
  getWorker(workerId: any, label: string) {
    // console.log('*** In getWorker, label=' + label);
    if ('yaml' === label) {
      return new YamlWorker();
    }
    return new EditorWorker();
  },
};
globals.supportsPaste = true;

export const useInitEditor = () => {
  const [loading, setLoading] = React.useState(false);
  // const [error, setError] = React.useState<any>(null);

  const initEditor = (editorContainer: HTMLElement, editorState?: MonacoEditorState) => {
    setLoading(true);
    // Future: setError(error);

    const diagnosticOpts = {
      validate: true,
      // Don't fetch remote schemas
      enableSchemaRequest: false,
      hover: true,
      completion: true,
      // Invoke the formatter with {Shift + Alt + F} or { right mouse click + Format Document }
      // Disabled for now: 
      format: false,
      schemas: [
        {
          uri: "http://apisimulator.io/schemas/apisim-schema.json",
          //fileMatch: [modelUri.toString()], // associate with our model
          fileMatch: ['*'],
          allowComments: true,
          schema: apisimDslSchema
        }
      ]
    };

    // @BEG !!! DUP from monaco-yaml/lib/monaco.d.ts!!!
    // Can't use `monaco.languages.yaml.yamlDefaults.setDiagnosticsOptions(diagnosticOpts);`
    // because of "Property 'yaml' does not exist on type 'typeof languages'" error.
    // It appears that there's a conflict between `monaco` from 'monaco-editor' and 
    // `monaco.languages.yaml` from '/monaco-yaml/lib/monaco.d.ts'
    const yamlDefaults = new LanguageServiceDefaultsImpl('yaml', diagnosticOpts);
    // ... skipping a few lines
    monaco.languages.register({
      id: 'yaml',
      extensions: ['.yaml', '.yml'],
      aliases: ['YAML', 'yaml', 'YML', 'yml'],
      mimetypes: ['application/x-yaml'],
    });
    monaco.languages.onLanguage('yaml', () => {
      setupMode(yamlDefaults);
    });
    // @END !!! DUP from monaco-yaml/lib/monaco.d.ts!!!

    // TODO Implement `monaco.editor.setModelMarkers...`. See index.js in api-simlet-editor
    // // Using `monaco.editor.getModelMarkers({owner: 'yaml'})` inside 
    // // `editor.onDidChangeModelDecorations(() => {..}) slows down everything as it 
    // // is called for every change. Instead, intercepting monaco.editor.setModelMarkers
    // // helps by processing markers only when there are changes to them
    // const setModelMarkers = monaco.editor.setModelMarkers;
    // monaco.editor.setModelMarkers = (model, owner, markers) => {
    //   setModelMarkers.call(monaco.editor, model, owner, markers);
    //   if (markers.length === 0) {
    //     console.log("No Errors");
    //   } else {
    //     console.log("Errors! count=" + markers.length);
    //     for (const marker of markers) {
    //       console.log("(" + marker.startLineNumber + ":" + marker.startColumn + ") " + marker.message);
    //       // export interface IMarker {
    //       //   owner: string;
    //       //   resource: Uri;
    //       //   severity: MarkerSeverity;
    //       //   code?: string | {
    //       //     value: string;
    //       //     link: Uri;
    //       //   };
    //       //   message: string;
    //       //   source?: string;
    //       //   startLineNumber: number;
    //       //   startColumn: number;
    //       //   endLineNumber: number;
    //       //   endColumn: number;
    //       //   relatedInformation?: IRelatedInformation[];
    //       //   tags?: MarkerTag[];
    //       // }
    //     }
    //   }
    // };

    // See 
    // https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
    monaco.editor.defineTheme('myTheme', {
      base: 'vs',
      inherit: true,
      rules: [{ token: '*', background: 'EDF9FA' }],
      colors: {
        // 'editor.foreground': '#000000',
        // 'editor.background': '#EDF9FA',
        // 'editorCursor.foreground': '#8B0000',
        'editor.lineHighlightBackground': '#0000FF20',
        // 'editorLineNumber.foreground': '#008800',
        // 'editor.selectionBackground': '#88000030',
        // 'editor.inactiveSelectionBackground': '#88000015'
      }
    });
    monaco.editor.setTheme('myTheme');

    // Create the editor
    const editor = monaco.editor.create(editorContainer, {
      // --- IStandaloneEditorConstructionOptions ---
      // If using editor.setModel(model) instead of the model property
      // here, monaco-yaml has a bug that causes this error:
      // "...TypeError: Cannot read property 'getModeId' of null
      //  at languageFeatures.js: 79"
      // Don't use null but use undefined instead!
      model: editorState?.textModel ? editorState?.textModel : undefined,

      //value: 'simlet: ',

      language: 'yaml',

      // theme: {}

      // --- IEditorConstructionOptions ---
      // dimension: IDimension {
      //   width: number;
      //   height: number;
      // }

      // --- IEditorOptions ---
      automaticLayout: true,

      formatOnPaste: false, //true,
      codeLens: false,
      folding: false,

      // detecting links and making them clickable.
      links: false,

      // behavior and rendering of the minimap.
      minimap: {
        enabled: false,
      },

      // Make whitespace visible (e.g. show dots in place of spaces)
      // 'none' | 'boundary' | 'selection' | 'all'
      renderWhitespace: 'selection',

      // ISuggestOptions
      suggest: {
        insertMode: 'replace',
      },

      // ... there are more IEditorOptions ...

      // --- IGlobalEditorOptions ---
      /**
       * Controls whether completions should be computed based on words in the document.
       * Defaults to true.
       */
      // set to false so that only real suggestions will be included
      wordBasedSuggestions: false,

      // formatting-related
      detectIndentation: true,
      insertSpaces: true,
      tabSize: 2,

      // ... eventually more IGlobalEditorOptions...
    });
    //editor.setValue(editorText || "simlet: ");

    // if (editorState?.textModel) {
    //   editor.setModel(editorState?.textModel);
    // } 

    if (editorState?.viewState) {
      editor.restoreViewState(editorState?.viewState);
      editor.focus(); // also sets the cursor on where it was
    }

    // TODO allow changing the indentation/tab size
    // // To change the tab size and other options after the editor has been created:
    // // editor.getModel()?.updateOptions({ tabSize: 4 });

    // @BEG: Removing entries from the Context Menu
    // There isn't an API exposed by monaco-editor as of now. Hacking it...
    // 
    // Disabling editor's context menu causes the browser's context menu to be displayed
    // editor.updateOptions({ contextmenu: false });
    // This has no effect: editor.onContextMenu(() => {});
    // 
    // Get a list of all actions to find out the id-s
    // console.log("supported actions=(on next line)");
    // console.log(editor.getSupportedActions());
    const actionIdsToRemoveFromCtxMenu = [
      "editor.action.formatDocument",
      "editor.action.formatSelection",
    ];
    // Orginal idea, from 
    // https://github.com/microsoft/monaco-editor/issues/1567#issuecomment-749692773
    // It is for an older version and things have changed a lot.
    // NEW, for monaco-editor v0.20.0:
    const menuItems = MenuRegistry._menuItems;
    const filteredMenuItems: Map<string, any/* IMenuItem */[]> = new Map();
    for (const menuEntry of menuItems.entries()) {
      const loc = menuEntry[0];
      const items = menuEntry[1];

      const filteredItems: Array<any /* IMenuItem */> = [];
      items.forEach((item: any /* IMenuItem */) => {
        // submenu items don't have 'command' field
        if (item.command && actionIdsToRemoveFromCtxMenu.includes(item.command?.id)) {
          // console.log("Skipping " + item.command?.id)
        }
        else {
          filteredItems.push(item);
        }
      });

      filteredMenuItems.set(loc, filteredItems);
    }
    // Re-assign the new menu items map
    MenuRegistry._menuItems = filteredMenuItems;
    // @END: Removing entries from the Context Menu

    setLoading(false);

    return editor;
  }

  return {
    initEditor,
    loading,
    // error,
  }
}
