import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import RunningStateIcon from 'mdi-material-ui/RunFast';
import StoppedStateIcon from 'mdi-material-ui/Stop';
import useStyles from '../../modules/styles';

const SimulatorStateAvatar = (props: any) => {
  const classes = useStyles();

  const simulator = props.simulator;

  return (
    <React.Fragment>
      <Tooltip
        title={
          simulator.isRunning()
            ? "Running"
            // TODO Pending
            : "Stopped"
        }
        arrow
      >
        <Avatar
          className={
            simulator.isRunning()
              ? classes.simulatorAvatarRunning
              : classes.simulatorAvatarStopped
          }
          variant="rounded"
        >
          { /*
            simulator.isRunning()
              ? "1"
              : "0"
          */ }
          {
            simulator.isRunning()
              ? <RunningStateIcon />
              : <StoppedStateIcon />
          }
        </Avatar>
      </Tooltip>
    </React.Fragment >
  )
}

export default SimulatorStateAvatar;
