import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
  root: {
    backgroundColor: "#238441",
    color: "white",
    "&:hover": {
      backgroundColor: "#238441",
      // color: "#808000",
    },
  },
  // label: {
  //   textTransform: 'capitalize',
  // },
})(Button);

export default StyledButton;
