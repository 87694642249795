import React from 'react';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountIcon from 'mdi-material-ui/Account';
import LoginIcon from 'mdi-material-ui/Login';
import LogoutIcon from 'mdi-material-ui/Logout';
import { MenuItemIcon, MenuItemText } from './shared/MenuItems';


const UserMenu = React.forwardRef((props: any, ref: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      showMenu(theAnchor: HTMLElement) {
        setAnchorEl(theAnchor);
      }
    })
  );

  return (
    <React.Fragment>
      <Menu
        id="user-menu"
        keepMounted
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          wa-act="act_sign_in"
          // disabled
          onClick={() => {
            props.setStatusSnackbar({
              open: true,
              status: 'info',
              message: "Coming soon (Sign In)"
            })
          }}
        >
          <MenuItemIcon>
            <LoginIcon fontSize="small" />
          </MenuItemIcon>
          <MenuItemText primary="Sign In" />
        </MenuItem>
        <Divider />
        <MenuItem
          wa-act="act_account"
          disabled
        >
          <MenuItemIcon>
            <AccountIcon fontSize="small" />
          </MenuItemIcon>
          <MenuItemText primary="Account" />
        </MenuItem>
        <Divider />
        <MenuItem
          wa-act="act_sign_out"
          disabled
        >
          <MenuItemIcon>
            <LogoutIcon fontSize="small" />
          </MenuItemIcon>
          <MenuItemText primary="Sign Out" />
        </MenuItem>
      </Menu>
    </React.Fragment >
  )
})

export default UserMenu;
