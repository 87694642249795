import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from 'mdi-material-ui/Close';
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import StyledButton from './shared/StyledButtons';


const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  style: React.CSSProperties;
  // children: React.ReactNode;
  onClose: () => void;
  viewTitle: string;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  return (
    <MuiDialogTitle disableTypography
      // style={{
      //   backgroundColor: "#EEF7E7",
      // }}
    >
      <Typography variant="h6">{props.viewTitle}</Typography>
      {props.onClose ? (
        <IconButton 
          aria-label="close" 
          className={props.classes.closeButton} 
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const SimletDefView = React.forwardRef((props: any, ref) => {
  const theme = useTheme();

  const [viewTitle, setViewTitle] = React.useState("Simlet Viewer");
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(
    ref,
    () => ({
      showView(title: string) {
        if (title) {
          setViewTitle(title);
        }
        setOpen(true);
      }
    })
  )

  const handleClose = () => {
    setOpen(false);
  };

  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        // Don't use `paper` for scroll - it causes scroll bar 
        // (textarea's) inside a scroll bar (paper's)
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle 
          id="scroll-dialog-title"
          onClose={handleClose}
          style={{
            backgroundColor: "#EEF7E7",
          }}
          viewTitle={viewTitle}
        />
        <DialogContent dividers={true}>
          {props.viewcontent}
        </DialogContent>
        <DialogActions>
          <StyledButton
            wa-act="act_edit_simlet"
            onClick={() =>
              props.setStatusSnackbar({
                open: true,
                status: 'info',
                message: 'Coming soon (edit simlet; redeploy)',
              })
            }
          >
            {"Edit"}
          </StyledButton>
          <StyledButton onClick={handleClose}>
            {"OK"}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default SimletDefView;
