import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';


const OutlinedTextFieldBase = (props: any) => {
  return (
    <TextField {...props}
      variant="outlined"
    />
  )
};

const OutlinedTextField = styled(OutlinedTextFieldBase)`
  .MuiFormLabel-root.Mui-focused {
    color: #238441;
  },

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #238441;
  },

  .MuiOutlinedInput-root .Mui-focused {
    background-color: #FAFAFA;
  }
`

const OutlinedMultilineFieldBase = (props: any) => {
  // <TextField {...props}
  return (
    <OutlinedTextField {...props}
      margin="none"
      multiline
    />
  )
};

// It positions the vertical scroll bar on the right edge
// instead of with "padding: '18.5px 14px'"
export const OutlinedMultilineTextField = styled(OutlinedMultilineFieldBase)`
  .MuiOutlinedInput-multiline {
    padding-block: 10.5px;
    padding-right: 0px;
  },
`;

export default OutlinedTextField;
