import React from 'react';
import {
  // HttpHeader,
  HttpRequest,
  HttpResponse,
  // isNoBodyHttpMethod,
} from './http';
import {
  orchestratorUrl,
  simulatorProxyUrl
} from './orchestrator';

// Axios is adding support for AbortController but it's not merged yet
// (2021/01/17): https://github.com/axios/axios/pull/3305
// Also, if I recall correctly, Axios didn't allow for custom HTTP methods
// like LIST.
// Using the polyfill instead of the browser-native "fetch" even though
// contemporary browsers support fetch natively, hoping to get uniform
// behavior across browsers. Especially for detecting problem with the
// network caught in "xhr.onerror"
// This required adding "whatwg-fetch.d.ts" in the @types directory
//import 'whatwg-fetch';
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { Headers as FetchPolyfillHeaders } from 'whatwg-fetch';


export type SimletSpec = {
  simlet: string;
}

export type SimulationSpec = {
  name: string;
  simlets: SimletSpec[];
}

export class SimulatorSpec {
  public id: string;
  public name: string;
  public url: string | undefined;
  public cpu: string | undefined;
  public memory: string | undefined;
  // size: string;
  // rateLimit
  public version: string | undefined;
  public state: string | undefined;
  public apisims: SimulationSpec[] | undefined;

  constructor(that: SimulatorSpec) {
    this.id = that.id;
    this.name = that.name;
    this.url = that.url;
    this.cpu = that.cpu;
    this.memory = that.memory;
    this.version = that.version;
    this.state = that.state;
    this.apisims = that.apisims;
  }

  isRunning() {
    return "running" === this.state
  }
}

interface SimulatorsState {
  list: SimulatorSpec[];
}

const initialStateData: SimulatorsState = {
  list: [],
}

const simulatorsStateContext = React.createContext(initialStateData);

export function SimulatorsStateProvider(props: any) {
  // // const [stateData] = React.useState<SimulatorsState>(initialStateData);
  // // return <simulatorsStateContext.Provider value={stateData} {...props} />
  // const [simulators, setSimulators] = React.useState<SimulatorsState>(initialStateData);
  // return (
  //   <simulatorsStateContext.Provider
  //     value={simulators}
  //     setSimulators={setSimulators}
  //     {...props}
  //   />
  // )
  return <simulatorsStateContext.Provider value={initialStateData} {...props} />
}

export const useSimulators = () => {
  let simulators = React.useContext(simulatorsStateContext)
  return {
    simulators,
    setSimulators: (s: SimulatorSpec[]) => {
      simulators.list = s;
    }
  };
}


const LIST_SIMULATORS_PATH = "/api/v1/orgs/{org}/simulators";

export async function loadSimulators(
  abortController: AbortController
): Promise<SimulatorSpec[]> {

  const url = orchestratorUrl() + LIST_SIMULATORS_PATH.replace("{org}", "default");

  // const httpHeaders = new FetchPolyfillHeaders();
  // httpHeaders.append("content-type", "application/json");

  // Using an external AbortController
  const abortSignal = abortController.signal;

  const httpRequestInit: RequestInit = {
    method: 'GET',
    // headers: httpHeaders,
    body: '', //requestJsonString,
    // mode: (window.location.origin !== proxyUrl ? 'cors' : 'same-origin'),
    signal: abortSignal,
  };

  let timeoutMsg: string;
  let timeoutId = -1;
  if (abortController) {
    // TODO Make the timeout configurable. Possibly, allow the user to set it
    const timeoutMillis = 360000 // 2000
    timeoutId = setTimeout(() => {
      abortController.abort();
      // Set message AFTER the call to abort - see the 
      // "abort" event listener below (if one is used)
      timeoutMsg = "Request timed out after " + timeoutMillis + "ms";
    }, timeoutMillis);

    // abortSignal.addEventListener("abort", () => {
    // timeoutMsg = "Request aborted"
    //});
  }

  const fetchPromise: Promise<Response> = fetchPolyfill(url, httpRequestInit);
  return fetchPromise
    .then((response: Response) => {
      if (response.status !== 200) {
        // TODO parse out body '"errors": [{"msg": "..."}]', if present
        const errMsg = "Loading the Simulators failed with status=" + response.status;
        throw new Error(errMsg);
      }

      const simulators: SimulatorSpec[] = new Array<SimulatorSpec>();

      // Response Body
      const result: Promise<SimulatorSpec[]> = response.text().then((value) => {
        const jsonArray = JSON.parse(value);
        // console.log("jsonArray=\n");
        // console.log(jsonArray);

        jsonArray.forEach((jsonObject: any) => {
          let state = "stopped";
          if (jsonObject["replicas"] && jsonObject["replicas"] > 0) {
            state = "running";
          }

          simulators.push(
            new SimulatorSpec({
              id: jsonObject["id"],
              name: jsonObject["name"],
              url: jsonObject["url"],
              cpu: jsonObject["cpus"],
              memory: jsonObject["memory"],
              version: jsonObject["version"],
              // state: jsonObject["state"],
              state: state,
              "apisims": jsonObject["apisims"],
            } as SimulatorSpec)
          );
        });

        simulators.sort((e1: SimulatorSpec, e2: SimulatorSpec) => {
          if (e1.isRunning()) {
            if (!e2.isRunning()) {
              return -1;
            }
            // Both are running. Sort by name
            return e1.name?.toUpperCase() < e2.name?.toUpperCase() ? -1 : 1;
          }
          if (e2.isRunning()) {
            return 1;
          }
          // Both are not running. Sort by name
          return e1.name?.toUpperCase() < e2.name?.toUpperCase() ? -1 : 1;
        });

        return simulators;
      }).catch((error) => {
        // These Error-s get caught in the "catch" below
        if (error instanceof SyntaxError) {
          throw new Error("Invalid JSON from the server");
        }
        if (error instanceof Error) {
          throw error;
        }
        const errMsg = "Getting the response body failed with '" + error + "'";
        throw new Error(errMsg);
      });
      return result;
    }).then((simulators: SimulatorSpec[]) => {
      return simulators;
    }).catch((error) => {
      if (timeoutMsg) {
        throw new Error(timeoutMsg);
      }

      // One reason for error.message ==='Failed to fetch' is 
      // missing CORS headers in the response when expected.
      // 
      // The fetch polyfill errors out with "Network request failed" 
      // message when unable to connect (e.g. server is down) in both
      // Chrome and Firefox unless the timeout kicks in before the time
      // the browser waits to establish a connection is up (it seems it
      // is different per browser)
      if (error instanceof Error) {
        if (error.message && error.message.toLowerCase().indexOf("network") >= 0) {
          throw new Error("Unable to connect to the server. Network or server problems?");
        }
        // `fetch` throws DOMException('Aborted', 'AbortError') when the 
        // request is cancelled by a signal from the AbortController
        throw error;
      }
      throw new Error("" + error);
    }).finally(() => {
      clearTimeout(timeoutId);
    });
}


// // TODO TEMP: Do remove when actual calls to load simulators is implemented
// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }
// 
// // TODO org (and team?) arguments, or use JWT auth cookie?
// // TODO An actual call to the API Simulator Orchestrator
// export async function mockLoadSimulators(): Promise<SimulatorSpec[]> {
//   // For demo purposes
//   await sleep(2300); //1300)

//   const simulatorSpecs: SimulatorSpec[] = [
//     new SimulatorSpec({
//       name: "Demo",
//       url: "127.0.0.1:6090",
//       cpu: "200m",
//       memory: "384MiB",
//       version: "1.8",
//       state: "running",
//       "apisims": [
//         {
//           "name": "hello-world-sim",
//           "simlets": [
//             { "simlet": "hi" },
//             { "simlet": "hello-world" },
//             { "simlet": "apisimulator-proxy-connect" },
//             { "simlet": "howdy" },
//             { "simlet": "greetings" },
//             { "simlet": "apisimulator-simlet-404" },
//             { "simlet": "post-hi" },
//             { "simlet": "cors-options" },
//             { "simlet": "random-greeting" }
//           ]
//         } as SimulationSpec
//       ],
//     } as SimulatorSpec),
//     new SimulatorSpec({
//       name: "Mars",
//       url: "acme-tech-corp-abcxyz01.cloud.apisimulator.com",
//       cpu: "1",
//       memory: "1GiB",
//       version: "1.8",
//       state: "stopped",
//       apisims: [] as SimulationSpec[],
//     } as SimulatorSpec),
//     new SimulatorSpec({
//       name: "Neptun",
//       url: "acme-tech-corp-abcxyz02.cloud.apisimulator.com",
//       cpu: "500m",
//       memory: "512MiB",
//       version: "1.8",
//       state: "stopped",
//       apisims: [] as SimulationSpec[],
//     } as SimulatorSpec),
//   ]

//   return Promise.resolve(simulatorSpecs);
// }


export async function simulate(
  abortController: AbortController,
  simulator: SimulatorSpec, // | null,
  httpRequest: HttpRequest,
): Promise<HttpResponse | string> {

  // TODO org argument or authToken that contains it?
  const org = "default";
  const proxyUrl = simulatorProxyUrl(org, simulator.id);

  httpRequest.uri = (!httpRequest?.uri.startsWith('/') ? "/" : "") + httpRequest?.uri;

  if (!httpRequest.method) {
    return Promise.resolve("Value for HTTP Method is required!");
  }
  //const httpMethod = httpRequest.method;
  const httpMethod = "POST";

  // When using the fetch polyfill, there's an issue with using the Headers
  // object directly - that would be the native Headers. See also:
  // https://github.com/github/fetch/issues/860
  // Using string[][] to store the headers to avoid the issue doesn't work
  // because before calling xhr.setRequestHeader there's a type check for 
  // Headers, which fails. The solution here aliases the polyfill's Headers
  // object. Use FetchPolyfillHeaders everywhere here instead of Headers!
  // 
  // Duplicate entries for the same header name are consolidated
  // into a comma-delimited list of values. No can do, unfortunately
  // const httpHeaders = new FetchPolyfillHeaders();
  // httpRequest.headers.forEach((header: HttpHeader) => {
  //   const name: string = header.name;
  //   if (name && name.trim().length > 0) {
  //     const value = header.value;
  //     httpHeaders.append(name, value);
  //   }
  // });
  const httpHeaders = new FetchPolyfillHeaders();
  httpHeaders.append("content-type", "application/json");

  // const abortController = new AbortController()
  // Using an external AbortController
  const abortSignal = abortController.signal;

  const httpRequestBase: RequestInit = {
    method: httpMethod,
    headers: httpHeaders,
    // mode: (window.location.origin !== proxyUrl ? 'cors' : 'same-origin'),
    signal: abortSignal,
  };

  // Determine if the request method suports a body and add it if it does
  // let httpRequestInit: RequestInit;
  // if (isNoBodyHttpMethod(httpMethod)) {
  //   httpRequestInit = httpRequestBase;
  // }
  // else {
  //   httpRequestInit = {
  //     ...httpRequestBase,
  //     body: httpRequest.body,
  //   }
  // }

  // console.log("Request as JSON=")
  const requestJsonObject = httpRequest.asJson()
  const requestJsonString = JSON.stringify(requestJsonObject);
  // console.log(requestJsonString)
  const httpRequestInit: RequestInit = {
    ...httpRequestBase,
    body: requestJsonString,
  };

  let statusMsg: string;

  let timeoutId = -1;
  if (abortController) {
    // TODO Make the timeout configurable. Possibly, allow the user to set it
    const timeoutMillis = 360000 // 2000
    timeoutId = setTimeout(() => {
      abortController.abort()
      // Set message AFTER the call to abort - see event listener below
      statusMsg = "Request timed out after " + timeoutMillis + "ms";
    }, timeoutMillis)

    abortSignal.addEventListener("abort", () => {
      statusMsg = "Request cancelled"
    });
  }

  // console.log("Request to proxy simlet test request:")
  // console.log("proxyUrl=" + proxyUrl)
  // console.log("request=")
  // console.log(httpRequestInit)
  const fetchPromise: Promise<Response> = fetchPolyfill(proxyUrl, httpRequestInit);

  return fetchPromise
    .then((response: Response) => {
      // ORG:
      // const httpResponse: HttpResponse = new HttpResponse();

      // // Status
      // httpResponse.status.code = response.status;
      // httpResponse.status.reason = response.statusText;

      // // Headers
      // response.headers.forEach((value: string, key: string) => {
      //   httpResponse.headers.add(key, value);
      // });

      // NEW: Proxing the calls due to CORS
      const httpResponse: HttpResponse = new HttpResponse();

      // Body
      const result: Promise<HttpResponse> = response.text().then((value) => {
        // ORG: httpResponse.body = value;
        // return httpResponse;

        // NEW: Proxing the calls due to CORS
        if (response.status !== 200) {
          statusMsg = "" + value; //response.status;
        }
        else {
          const jsonObject = JSON.parse(value)
          // jsonObject.protocol // "http/1.1"

          // Status
          httpResponse.status.code = jsonObject.code;
          httpResponse.status.reason = jsonObject.reason;

          // Headers
          jsonObject.headers?.forEach((header: any) => {
            httpResponse.headers.add(header.name, header.value);
          });

          // Body
          // TODO: For now, only as text
          const bodyContent = atob(jsonObject.base64Body)
          httpResponse.body = bodyContent
        }
        return httpResponse;
      }).catch((error) => {
        const errMsg = "getting the response body failed with " + error;
        // console.log(errMsg);
        throw new Error(errMsg);
      });
      return result;
    }).then((httpResponse: HttpResponse) => {
      if (statusMsg) {
        throw new Error(statusMsg)
      }
      return httpResponse;
    }).catch((error) => {
      // One reason for error.message ==='Failed to fetch' is 
      // missing CORS headers in the response when expected.
      // 
      // The fetch polyfill errors out with "Network request failed" 
      // message when unable to connect (e.g. server is down) in both
      // Chrome and Firefox unless the timeout kicks in before the time
      // the browser waits to establish a connection is up (it seems it
      // is different per browser)
      const errMsg: string = statusMsg // && statusMsg.trim().length > 0
        ? statusMsg
        : (
          error.message
            ? (error.message.toLowerCase().indexOf("network") >= 0
              ? "Unable to connect to the server: Network or server problems?"
              : "Request failed with '" + error.message + "'"
            )
            : "Request failed with '" + error + "'"
        )
      return Promise.resolve(statusMsg ? statusMsg : errMsg);
    }).finally(() => {
      clearTimeout(timeoutId);
    });
}
