import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';


export const MenuItemIcon = withStyles((theme) => {
  return {
    root: {
      minWidth: "36px",
    },
  }
})(ListItemIcon);


export const MenuItemText = (props: any) => {
  return (
    <ListItemText primary={props.primary} {...props}>
      {props.children}
    </ListItemText>
  )
}
