import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
// import ApiIcon from 'mdi-material-ui/Api';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import ChevronUpIcon from 'mdi-material-ui/ChevronUp';
import SwapHorizontalIcon from 'mdi-material-ui/SwapHorizontal';
import styled from 'styled-components';
import {
  CancellablePromise,
  makeCancellable
} from '../../modules/cancellablePromise';
import { fetchSimletDefinition } from '../../modules/simlets';
import {
  SimletSpec,
  SimulationSpec,
  SimulatorSpec,
} from '../../modules/simulators';
import { OutlinedMultilineTextField } from '../shared/OutlinedTextFields';
import SimletDefView from '../SimletDefView';


// font family and size matche the ones for the Monaco editor. TEMP!
export const OutlinedViewTextField = styled(OutlinedMultilineTextField)`
  .MuiInputBase-input {
    font-family: Consolas, "Courier New", monospace;
    font-size: 14px;
  },
`;

function TransitionComponent(props: TransitionProps) {
  return (
    <Collapse {...props} />
  );
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover > $content': {
        backgroundColor: "transparent",
        // cursor: "auto",
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: "transparent",
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      // paddingRight: theme.spacing(1),
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',

      // Add invisible border so that the space is taken
      // to avoid content shifting if added only on hover
      borderBottom: '1px dashed',
      borderBottomColor: 'transparent',
      '&:hover': {
        // Make the border visible on hover to give it underlining effect
        borderBottomColor: theme.palette.grey[500],
      }
    },
  }),
)((props: TreeItemProps) =>
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
);


const SimulationsTree = (props: any) => {
  const simulator: SimulatorSpec = props.simulator;
  const apisims = simulator.apisims;

  const cancelControllerRef = React.useRef<AbortController>(new AbortController());
  const cancellableLoadingPromiseRef = React.useRef<CancellablePromise<string>>();

  const [simletDef, setSimletDef] = React.useState<string>("");
  const simletDefViewRef = React.useRef<any>();

  const simletClickHandler = (
    simulatorId: string,
    simName: string,
    simletName: string
  ) => {
    // TODO Look into caching and cache invalidation for simlet definitions
    const loadingPromise = fetchSimletDefinition(
      cancelControllerRef.current, simulatorId, simName, simletName
    );
    cancellableLoadingPromiseRef.current = makeCancellable(loadingPromise);
    cancellableLoadingPromiseRef.current.promise
      .then((simletDef: string) => {
        // console.log("simletDef=(next line line)");
        // console.log(simletDef);

        // if (simletDef) {
        //   simletDef.replaceAll('\r\n', '\n');
        // }
        setSimletDef(simletDef);
        simletDefViewRef.current.showView("Simlet: " + simletName);
      })
      .catch((error) => {
        if (error.isCanceled) {
          return; // Do nothing
        }

        const errMsg = (
          error instanceof Error
            ? error.message
            : "" + error
        );
        props.setStatusSnackbar({
          open: true,
          status: 'error',
          message: errMsg,
        });
      })
      .finally(() => {
        // // Initialize for another potential cancelation
        // if (cancelControllerRef.current.signal.aborted) {
        //   cancelControllerRef.current = new AbortController();
        // }

        // // Don't call setLoading to set state if the promise
        // // has been cancelled or it will otherwise cause "Can't perform a
        // // React state update on an unmounted component. This is a no-op, 
        // // but it indicates a memory leak in your application." upon 
        // // navigating away while the fetch call is in progress
        // if (!cancellableLoadingPromiseRef.current?.isCanceled()) {
        //   setLoading(false);
        // }
      });
  }

  if (apisims && apisims.length > 0) {
    return (
      <React.Fragment>
        <TreeView
          defaultCollapseIcon={
            <ChevronUpIcon style={{ cursor: "pointer", }} />
          }
          defaultExpandIcon={
            <ChevronDownIcon style={{ cursor: "pointer", }} />
          }
          disableSelection={true}
        >
          {
            apisims.map((sim: SimulationSpec, simIndex: number) => {
              const simName = sim.name;
              return (
                <StyledTreeItem
                  wa-act="act_sim_simlets"
                  key={simName + simIndex}
                  nodeId={simName}
                  label={
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      { /* <ApiIcon /> */}
                      <Typography>
                        {simName}
                      </Typography>
                    </div>
                  }
                >
                  {
                    sim.simlets.map((simlet: SimletSpec, simletIndex: number) => {
                      const simletName = simlet.simlet;
                      return (
                        <StyledTreeItem
                          wa-act="act_view_simlet"
                          key={simName + simletIndex}
                          nodeId={simName + simletIndex}
                          label={simletName}
                          icon={
                            <Tooltip title="Simlet" arrow>
                              <SwapHorizontalIcon />
                            </Tooltip>
                          }
                          onClick={() => {
                            simletClickHandler(simulator.id, simName, simletName)
                          }}
                        />
                      )
                    })
                  }
                </StyledTreeItem>
              )
            })
          }
        </TreeView >
        <SimletDefView
          ref={simletDefViewRef}
          viewcontent={
            <OutlinedViewTextField
              id="simlet-definition"
              fullWidth
              // Don't use `label` - it causes confusing experience
              // when the the simlet definition is empty (missing)
              // label="Simlet"
              rows={16}
              value={simletDef}
            />
          }
          // Pass along the props, including the setter for status snackbar
          {...props}
        />
      </React.Fragment >
    )
  }

  return (
    <Typography>
      { /* {"None"} */}
    </Typography>
  )
}

export default SimulationsTree;