import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import useStyles from './modules/styles';
import { MonacoEditorStateProvider } from './modules/monacoEditorState';
import { SimletTesterStateProvider } from './modules/simletTester';
import { SimulatorsStateProvider } from './modules/simulators';
import {
  StatusSnackbar,
  StatusSnackbarState,
} from './components/shared/StatusSnackbar';


const Suspense = React.Suspense;

export default function App(props: any) {
  const classes = useStyles();

  const [statusSnackbar, setStatusSnackbar] = React.useState<StatusSnackbarState>({
    open: false,
    status: 'error',
    message: 'Init error: no message provided'
  });

  return (
    <Router>
      <div className={classes.root}>
        <Suspense
          fallback={
            // The Backdrop is controlled by Suspense
            <Backdrop
              className={classes.progressBackdrop}
              open={true}
              transitionDuration={0}
            >
              <CircularProgress
                variant="indeterminate"
                size={44}
                thickness={4}
                color="primary"
                classes={{
                  colorPrimary: classes.circularProgressColor,
                }}
              />
            </Backdrop>
          }
        >
          <SimulatorsStateProvider>
            <SimletTesterStateProvider>
              <MonacoEditorStateProvider>
                {
                  // The StatusSnackbar is created only once and child
                  // components invoke it using `props.setStatusSnackbar`
                }
                <StatusSnackbar
                  id="statusSnackbar"
                  statusSnackbar={statusSnackbar}
                  setStatusSnackbar={setStatusSnackbar}
                />
                <Header setStatusSnackbar={setStatusSnackbar} {...props} />
                <Home setStatusSnackbar={setStatusSnackbar} {...props} />
              </MonacoEditorStateProvider>
            </SimletTesterStateProvider>
          </SimulatorsStateProvider>
        </Suspense>
      </div>
    </Router>
  )
}
