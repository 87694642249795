import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

const drawerWidth = 172;

const useStyles = makeStyles((theme: Theme) => {
  // const themePaperColor = theme.palette.background.paper;

  const themeInfoBackgroundColor = theme.palette.info.main;
  const themeInfoColor = theme.palette.info.contrastText;

  const themeErrorBackgroundColor = theme.palette.error.main
  const themeErrorColor = theme.palette.error.contrastText;

  const simZIndexAppBar = theme.zIndex.drawer + 1;
  const simZIndexPopper = simZIndexAppBar + 1;

  const simBackgroundGreen = "#238441";

  return createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
      "&:Mui-focused": {
        color: simBackgroundGreen,
      },
    },

    appBar: {
      backgroundColor: "#EEF7E7",
      zIndex: simZIndexAppBar, // theme.zIndex.drawer + 1,
    },

    bringToFront: {
      zIndex: simZIndexPopper + 1, //theme.zIndex.drawer + 2,
    },

    noTabIndicator: {
      // Hide the indicator as sometimes the first time a tab
      // gets the focus the indicator isn't displayed at all
      height: 0,
    },
    menuTabTextColorPrimary: {
      backgroundColor: "#EEF7E7",
      color: simBackgroundGreen,
      "&.Mui-selected": {
        backgroundColor: "white",
        color: "#024900",
      },
    },

    appMain: {
      // "& > *": {
      //   margin: theme.spacing(1),
      // },
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),

      height: "100%",
    },

    progressBackdrop: {
      zIndex: theme.zIndex.appBar + 1,
      backgroundColor: "#FFFFFFA3",
    },
    circularProgressColor: {
      color: simBackgroundGreen,
    },
    // Center over the previous element (an action button)
    buttonProgress: {
      position: "absolute",
      top: "0%",
      left: "50%",
      transform: "translateX(-50%)",
      "&.MuiButton-root": {
        color: simBackgroundGreen,
      },
      "&:hover": {
        color: "red",
      },
    },

    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    drawerPaper: {
      top: "auto",
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },

    // This defines min-height
    toolbarMargin: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      height: "100%",
      marginLeft: -drawerWidth,
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      marginLeft: drawerWidth,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    infoBackgroundColor: {
      color: themeInfoBackgroundColor
    },

    // Reportedly from
    // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
    popper: {
      zIndex: simZIndexPopper, // 2000,
      '&[x-placement*="bottom"] $popperArrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "0 100%"
        }
      },
      '&[x-placement*="top"] $popperArrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "100% 0"
        }
      },
      '&[x-placement*="right"] $popperArrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "100% 100%"
        }
      },
      '&[x-placement*="left"] $popperArrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "0 0"
        }
      }
    },
    // Reportedly from
    // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    popperArrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      color: themeInfoBackgroundColor,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: theme.shadows[1],
        backgroundColor: "currentColor",
        transform: "rotate(45deg)"
      }
    },
    popperRoot: {
      backgroundColor: themeInfoBackgroundColor,
      color: themeInfoColor,
      maxWidth: "320px",
    },
    popperContent: {
      padding: theme.spacing(1),
    },

    simulatorAvatarRunning: {
      // When this MUI class is also defined for the element
      "&.MuiAvatar-colorDefault": {
        color: theme.palette.getContrastText(simBackgroundGreen),
        backgroundColor: simBackgroundGreen,
      },
    },
    simulatorAvatarStopped: {
      // When this MUI class is also defined for the element
      "&.MuiAvatar-colorDefault": {
        color: themeErrorColor, // theme.palette.getContrastText(red[500]),
        // This red is the same as for error alerts' background
        backgroundColor: themeErrorBackgroundColor, // red[500],
      }
    },

    accordionSummaryExpanded: {
      "&.MuiAccordionSummary-content.Mui-expanded": {
        margin: "0px",
      }
    },

  })
});

export default useStyles;
