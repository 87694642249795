import React from 'react';
import { HttpRequest } from './http';
import { SimulatorSpec } from './simulators';

export interface SimletTesterState {
  simulator: SimulatorSpec | null;
  httpRequest: HttpRequest;
  // httpResponse: HttpResponse; // | null;
  response: string;
}

const initialHttpRequest = new HttpRequest();
initialHttpRequest.method = 'GET';
initialHttpRequest.uri = '/hi?name=Luke Skywalker';
initialHttpRequest.body = ''; //'{ "name": "Luke" }';

const initialStateData: SimletTesterState = {
  simulator: null,
  httpRequest: initialHttpRequest,
  // httpResponse: new HttpResponse(),
  response: ''
}

const simletTesterStateContext = React.createContext(initialStateData);

export function SimletTesterStateProvider(props: any) {
  return <simletTesterStateContext.Provider value={initialStateData} {...props} />
}

export const useSimletTester = () => {
  const context = React.useContext(simletTesterStateContext)
  return context;
}
