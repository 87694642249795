import { fetch as fetchPolyfill } from 'whatwg-fetch';
// import { Headers as FetchPolyfillHeaders } from 'whatwg-fetch';
import { adminProxyUrl } from './orchestrator';


const GET_SIMLETS_PATH = "/api/v1/apisims/{simName}/simlets/{simletName}";

// ORG argument?
export async function fetchSimletDefinition(
  abortController: AbortController,
  simulatorId: string,
  simName: string,
  simletName: string,
) {

  const org = "default";
  const url = adminProxyUrl(org, simulatorId) +
    GET_SIMLETS_PATH.replace("{simName}", simName).replace("{simletName}", simletName);

  // const httpHeaders = new FetchPolyfillHeaders();
  // httpHeaders.append("content-type", "application/json");

  // Using an external AbortController
  const abortSignal = abortController.signal;

  const httpRequestInit: RequestInit = {
    method: 'GET',
    // headers: httpHeaders,
    body: '', //requestJsonString,
    // mode: (window.location.origin !== proxyUrl ? 'cors' : 'same-origin'),
    signal: abortSignal,
  };

  let timeoutMsg: string;
  let timeoutId = -1;
  if (abortController) {
    // TODO Make the timeout configurable. Possibly, allow the user to set it
    const timeoutMillis = 360000 // 2000
    timeoutId = setTimeout(() => {
      abortController.abort();
      // Set message AFTER the call to abort - see the 
      // "abort" event listener below (if one is used)
      timeoutMsg = "Request timed out after " + timeoutMillis + "ms";
    }, timeoutMillis);

    // abortSignal.addEventListener("abort", () => {
    // timeoutMsg = "Request aborted"
    //});
  }

  const fetchPromise: Promise<Response> = fetchPolyfill(url, httpRequestInit);
  return fetchPromise
    .then((response: Response) => {
      if (response.status !== 200) {
        // TODO parse out body '"errors": [{"msg": "..."}]', if present
        const errMsg =
          "Loading simlet '" + simName + "' failed with status=" + response.status;
        throw new Error(errMsg);
      }
      // Response Body
      const result: Promise<string> = response.text().then((value) => {
        const jsonObject = JSON.parse(value);
        // console.log("jsonObject=\n");
        // console.log(jsonObject);

        const simletDef = jsonObject["model"]?.["dsl"];
        return simletDef;
      }).catch((error) => {
        // These Error-s get caught in the "catch" below
        if (error instanceof SyntaxError) {
          throw new Error("Invalid JSON from the server");
        }
        if (error instanceof Error) {
          throw error;
        }
        const errMsg = "Getting the response body failed with '" + error + "'";
        throw new Error(errMsg);
      });
      return result;
    }).then((simletDef: string) => {
      return simletDef;
    }).catch((error) => {
      if (timeoutMsg) {
        throw new Error(timeoutMsg);
      }

      // One reason for error.message ==='Failed to fetch' is 
      // missing CORS headers in the response when expected.
      // 
      // The fetch polyfill errors out with "Network request failed" 
      // message when unable to connect (e.g. server is down) in both
      // Chrome and Firefox unless the timeout kicks in before the time
      // the browser waits to establish a connection is up (it seems it
      // is different per browser)
      if (error instanceof Error) {
        if (error.message && error.message.toLowerCase().indexOf("network") >= 0) {
          throw new Error("Unable to connect to the server. Network or server problems?");
        }
        // `fetch` throws DOMException('Aborted', 'AbortError') when the 
        // request is cancelled by a signal from the AbortController
        throw error;
      }
      throw new Error("" + error);
    }).finally(() => {
      clearTimeout(timeoutId);
    });
}
