/*
 * Based om these sources, with modifications and enhancements:
 * https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
 * https://github.com/facebook/react/issues/5465#issuecomment-157888325
 */
import React from 'react';

export interface CancellablePromise<T> {
  promise: Promise<T>;

  isCanceled: () => boolean;

  cancel: () => void;
}


export function makeCancellable<T>(promise: Promise<T>): CancellablePromise<T> {
  let _isCanceled = false;

  const wrapperPromise: Promise<T> = new Promise((resolve, reject) => {
    promise
      .then((value) =>
        _isCanceled
          ? reject({ isCanceled: true })
          : resolve(value)
      )
      .catch((error: Error) =>
        _isCanceled
          ? reject({ isCanceled: true })
          : reject(error)
      );
  });

  const cancellablePromise: CancellablePromise<T> = {
    promise: wrapperPromise,
    isCanceled() {
      return _isCanceled;
    },
    cancel() {
      _isCanceled = true;
    }
  }
  return cancellablePromise;
}

// React hook to create CancellablePromise-s and to clean them up on unmounting
// A problem with this hook is that it accumulates _all_ promises created on a
// page even after they are resolved or rejected
export default function useCancellablePromise() {
  const cancellablePromises = React.useRef<CancellablePromise<any>[]>([])

  React.useEffect(
    () => {
      // This cleanup gets executed on component unmounting
      return function cleanup() {
        cancellablePromises.current
          .filter((cancellablePromise) => !cancellablePromise.isCanceled())
          .forEach((cancellablePromise) => {
            // console.log("Cancelling promise=" + cancellablePromise)
            cancellablePromise.cancel()
          });
        cancellablePromises.current = []
      };
    }, []
  );

  const cancellablePromise = <T>(promise: Promise<T>): CancellablePromise<T> => {
    const cancellablePromise = makeCancellable(promise)
    cancellablePromises.current.push(cancellablePromise)
    // return cancellablePromise.promise;
    //
    // Usage: 
    // cancellablePromise.promise \
    //  .then((value) => {}) \
    //  .catch(({ isCanceled, ...error }) => {}) \
    //  .finally(() => {})
    // Check if it's been cancelled: 
    // cancellablePromise.isCancelled()
    return cancellablePromise;
  }

  return { cancellablePromise };
}
