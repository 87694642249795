import React from 'react';
import * as monaco from 'monaco-editor';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
// Using this format to allow for tree-shaking
import ContentCopy from 'mdi-material-ui/ContentCopy';
import ContentCut from 'mdi-material-ui/ContentCut';
import ContentPaste from 'mdi-material-ui/ContentPaste';
import Download from 'mdi-material-ui/Download';
import FileDocumentEditOutline from 'mdi-material-ui/FileDocumentEditOutline';
import FolderOpenOutline from 'mdi-material-ui/FolderOpenOutline';
import FormatIndentDecrease from 'mdi-material-ui/FormatIndentDecrease';
import FormatIndentIncrease from 'mdi-material-ui/FormatIndentIncrease';
import Help from 'mdi-material-ui/Help';
// import LanguageFortran from 'mdi-material-ui/LanguageFortran';
import Redo from 'mdi-material-ui/Redo';
// import ReplyAllOutline from 'mdi-material-ui/ReplyAllOutline';
// import ReplyOutline from 'mdi-material-ui/ReplyOutline';
import SelectAll from 'mdi-material-ui/SelectAll';
import Undo from 'mdi-material-ui/Undo';
import HelpWidget from './shared/HelpWidget';


const SIMLET_EMPTY_TEMPLATE = `simlet: 

request:
  - method: 
  #- uriPath: 

response:
  from: 
  #headers:
  #  - "content-type: application/json"
  #body: 
`;

const replaceAll = (editor: monaco.editor.IStandaloneCodeEditor, newContent: string) => {
  const fullRange = editor.getModel()?.getFullModelRange();
  if (fullRange) {
    // Selecting the whole old content and then inserting the new
    // content works but an undo action shows the old content selected
    // editor.setSelection(fullRange);

    // So, taking a different approach:

    // Delete the existing content
    editor.executeEdits('source', [
      {
        range: fullRange,
        text: "",
        forceMoveMarkers: true
      }
    ]);

    // Insert the new content
    editor.executeEdits('source', [
      {
        range: fullRange,
        text: newContent,
        forceMoveMarkers: true
      }
    ]);

    editor.setSelection(new monaco.Range(0, 0, 0, 0));

    // Setting the focus fixes a problem with otherwise not preserving
    // the state (current content) when navigating away and before the
    // focus is set to the editor's content
    editor.focus();
    // Also scroll to the first line (if needed)...
    editor.setScrollTop(1);
    // ...and set the cursor at the beginning
    editor.setPosition(new monaco.Position(1, 1));
  }
}

const triggerSimletUploadHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  const element = document.getElementById("uploadMediatorInput") as HTMLInputElement;
  if (element) {
    if (element.onchange) {
      // NOOP
    }
    else {
      element.onchange = (event: any) => {
        const uploadedFile = event.target.files[0];
        uploadedFile.text()
          .then((t: string) => {
            // setValue leaves no trace in history for undo/redo
            // editor.setValue(t);
            replaceAll(editor, t);

            // Reset the input field value. 
            // Important to be able to re-use the input field!
            element.value = "";
          })
          .catch((e: any) => {
            console.log(e)
            alert(e);
            element.value = "";
          });
      };
    }
    element.click();
  }
}

const downloadSimletHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  const text = editor.getValue();
  const file = new Blob([text], { type: 'text/plain' });

  // Create a new anchor element each time
  // const element = document.createElement("a");
  // element.href = URL.createObjectURL(file);
  // element.download = "simlet.yaml";
  // document.body.appendChild(element); // Required for this to work in Firefox
  //element.click();

  // Reuse the same anchor element. 
  // Should somehow the download button be disabled and re-enabled to avoid race conditions?
  const element = document.getElementById("downloadMediatorLink") as HTMLLinkElement;
  if (element) {
    element.href = URL.createObjectURL(file);
    // element.download = "simlet.yaml";
    element.click();
  }
}

const selectAllHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  const fullRange = editor.getModel()?.getFullModelRange();
  if (fullRange) {
    editor.setSelection(fullRange);
  }
}

const copyToClipboardHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  // It seems that by default copy-to-clipboard will copy the whole current line
  // when there isn't any text selected. We want it to copy only selected text
  const selection = editor.getSelection();
  if (selection) { // Per the docs, selection could be null
    if (selection.selectionStartLineNumber === selection.positionLineNumber &&
      selection.selectionStartColumn === selection.positionColumn) {
      // No selected text
    }
    else {
      editor.trigger('source', 'editor.action.clipboardCopyAction', {});
    }
  }
}

const cutToClipboardHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  // It seems that by default cut-to-clipboard will cut and copy the whole current
  // line when there isn't any text selected. We want it to copy only selected text
  const selection = editor.getSelection();
  if (selection) { // Per the docs, selection could be null
    if (selection.selectionStartLineNumber === selection.positionLineNumber &&
      selection.selectionStartColumn === selection.positionColumn) {
      // No selected text
    }
    else {
      editor.trigger('source', 'editor.action.clipboardCutAction', {});
    }
  }
}

const pasteFromClipboardHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  editor.focus();

  // NOTE1: For security reasons it is not currently possible to use scripts to simulate 
  // user actions that interact with the browser itself, like pressing { Ctrl + V} to 
  // paste content from the clipboard.
  // NOTE2: The 'editor.action.clipboardPasteAction' action is disabled in the browser
  // editor.trigger('source', 'editor.action.clipboardPasteAction', {});

  if (navigator.clipboard && navigator.clipboard.readText) {
    navigator.clipboard.readText().then((text) => {
      var selection = editor?.getSelection();
      if (selection) {
        var range = new monaco.Range(
          selection.startLineNumber,
          selection.startColumn,
          selection.endLineNumber,
          selection.endColumn
        );
        var id = { major: 1, minor: 1 };
        var op = {
          identifier: id,
          range: range,
          text: text,
          forceMoveMarkers: true
        };
        editor?.executeEdits("", [op]);
      }
    })
  }
  else if (!document.execCommand('paste')) {
    // Firefox doesn't currently allow reading from the clipboard
    alert(
      `Your browser doesn't support pasting text from
the Clipboard programatically. Use Ctrl + V 
(or Cmd + V on a Mac) key combination instead`
    );
  }
}

// Formatting is for now disabled
// const formatDocumentHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
//   editor.trigger('source', 'editor.action.formatDocument', {});
// }

const indentLinesHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  editor.trigger('source', 'editor.action.indentLines', {});
}

const outdentLinesHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
  editor.trigger('source', 'editor.action.outdentLines', {});
}

function keyBinding(winKeys: string, macKeys: string, desc: string) {
  return { winKeys, macKeys, desc };
}

const keyBindingsHelp = [
  keyBinding("Ctrl+Space", "⌃Space", "Trigger suggestion"),
  keyBinding("Ctrl+/", "⌘/", "Toggle line comment"),
  // keyBinding("Shift+Alt+F", "⇧⌥F", "Format document"),
  keyBinding("Ctrl+] / [", "⌘] / ⌘[", "Indent/outdent current line"),
  keyBinding("Ctrl+F", "⌘F", "Find"),
  keyBinding("Ctrl+H", "⌥⌘F", "Replace"),
  keyBinding("F3 / Shift+F3", "⌘G / ⇧⌘G", "Find next/previous"),
  keyBinding("Home / End", "Home / End", "Go to beginning/end of line"),
  keyBinding("Ctrl+Home", "⌘↑", "Go to beginning of document"),
  keyBinding("Ctrl+End", "⌘↓", "Go to end of document"),
  keyBinding("Ctrl+G", "⌃G", "Go to Line..."),
  keyBinding("F8", "F8", "Go to next error or warning"),
  keyBinding("Shift+F8", "⇧F8", "Go to previous error or warning"),
];

const OutlinedPaper = (props: any) => {
  return <Paper variant="outlined">{props.children}</Paper>;
};

function EditorHelpContent() {
  // const classes = useStyles();

  return (
    <div>
      <Typography variant="body1" gutterBottom>
        Use the Simlet Editor to model simlets with the help of auto-completion,
        suggestions, syntax highlighting, immediate feedback on errors, and more.
      </Typography>
      <Divider />
      <Typography variant="h6">
        Keyboard Shortcuts
      </Typography>
      <Typography variant="body1">
        There are more keyboard shortcuts but these should help to get you going:
      </Typography>
      <TableContainer component={OutlinedPaper}>
        <Table
          // className={classes.table} 
          size="small"
          aria-label="Simlet Editor Help"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
              >
                Windows
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
              >
                Mac
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
              >
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keyBindingsHelp.map((keyBinding) => (
              <TableRow key={keyBinding.winKeys}>
                <TableCell align="left">{keyBinding.winKeys}</TableCell>
                <TableCell align="left">{keyBinding.macKeys}</TableCell>
                <TableCell align="left">{keyBinding.desc}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Divider />
      <Typography variant="h6">
        Tips, Known Limitations, and Quirks
      </Typography>
      <List>
        <ListItem>
          <ListItemText>
            Comments start with a pound sign `#` and are scoped until the end
            of the line.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Detected errors are underlined with a little squiggle and their
            relative position in the document is shown on the vertical scroll
            bar.
          </ListItemText>
        </ListItem>
        { /*
        <ListItem>
          <ListItemText>
            Multi-line text surrounded by backtick characters (``) is exclusive
            to API Simulator to make it easier for you to work with multi-line
            text values like HTTP response bodies. Formatting doesn't work,
            though, if the simlet definition uses such multi-line text.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            If formatting doesn't work when <i>not</i> using multi-line text
          surrounded by backtick characters, make sure that there are no
          errors. Fix any errors first and then format the document again.
          </ListItemText>
        </ListItem>
        */ }
        <ListItem>
          <ListItemText>
            For security reasons, browsers may block JavaScript pasting text
            from the clipboard. Use the corresponding key shortcuts (e.g.
            Ctrl+V).
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            The content of the editor will be lost when you close the browser
            tab or the whole browser. Download the simlet or copy editor's
            content in the clipboard so you don't lose it.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Please let us know if a simlet definition is marked as containing
            errors when you think that it is proper syntax. We'll update the
            JSON schema for the syntax validation accordingly, if needed.
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}

// This appears in dev mode and the first time hovering over a menu button:
// "...Warning: findDOMNode is deprecated in StrictMode. findDOMNode
// was passed an instance of Transition which is inside StrictMode".
// General workarounds for this warning don't work and it seems that
// the Material UI team has to fix it.
// Notice that the warning does NOT show in a production build.
function ToolbarButton(props: any) {
  // Without the minWidth style setting, the button width changes
  // for some reason to 64px wide when switching between views
  return (
    <Tooltip title={props.tooltip} arrow
      style={{ minWidth: "48px" }}
    >
      <Button {...props} />
    </Tooltip>
  );
}

export default function DslEditorCommandPalette(props: any) {
  const editor = props.editor;

  const helpWidgetRef = React.useRef<any>();

  return (
    <div style={{ display: "inline-block" }}>
      <ButtonGroup>
        <ToolbarButton
          wa-act="act_new_simlet"
          tooltip={"New Simlet"}
          onClick={() => {
            // setValue leaves no trace in history for undo/redo
            // editor?.setValue(SIMLET_EMPTY_TEMPLATE) 
            editor && replaceAll(editor, SIMLET_EMPTY_TEMPLATE);
          }}
        >
          <FileDocumentEditOutline />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_open_simlet"
          tooltip="Open Simlet File"
          onClick={() => { editor && triggerSimletUploadHandler(editor) }}
        >
          <FolderOpenOutline />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_download_simlet"
          tooltip="Download Simlet"
          onClick={() => { editor && downloadSimletHandler(editor) }}
        >
          <Download />
        </ToolbarButton>
      </ButtonGroup>
      {" "}
      <ButtonGroup>
        <ToolbarButton
          wa-act="act_undo"
          tooltip="Undo (Ctrl/Cmd + Z)"
          onClick={() => { editor?.trigger('source', 'undo', {}) }}
        >
          <Undo />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_redo"
          tooltip="Redo (Ctrl/Cmd + Y)"
          onClick={() => { editor?.trigger('source', 'redo', {}) }}
        >
          <Redo />
        </ToolbarButton>
      </ButtonGroup>
      {" "}
      <ButtonGroup>
        <ToolbarButton
          wa-act="act_select_all"
          tooltip="Select All (Ctrl/Cmd + A)"
          onClick={() => { editor && selectAllHandler(editor) }}
        >
          <SelectAll />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_clipboard_copy"
          tooltip="Copy (Ctrl/Cmd + C)"
          onClick={() => { editor && copyToClipboardHandler(editor) }}
        >
          <ContentCopy />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_clipboard_cut"
          tooltip="Cut (Ctrl/Cmd + X)"
          onClick={() => { editor && cutToClipboardHandler(editor) }}
        >
          <ContentCut />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_clipboard_paste"
          tooltip="Paste (Ctrl/Cmd + V)"
          onClick={() => { editor && pasteFromClipboardHandler(editor) }}
        >
          <ContentPaste />
        </ToolbarButton>
      </ButtonGroup>
      {" "}
      <ButtonGroup>
        { /*
        <ToolbarButton
          wa-act="act_format"
          tooltip="Format (Shift + Alt + F)"
          onClick={() => { editor && formatDocumentHandler(editor) }}
        >
          <LanguageFortran />
        </ToolbarButton>
        */ }
        <ToolbarButton
          wa-act="act_dec_indentation"
          tooltip="Decrease Indentation (Shift + Tab)"
          onClick={() => { editor && outdentLinesHandler(editor) }}
        >
          <FormatIndentDecrease />
        </ToolbarButton>
        <ToolbarButton
          wa-act="act_inc_indentation"
          tooltip="Increase Indentation (Tab)"
          onClick={() => { editor && indentLinesHandler(editor) }}
        >
          <FormatIndentIncrease />
        </ToolbarButton>
      </ButtonGroup>
      {" "}
      <ButtonGroup>
        <ToolbarButton 
          wa-act="act_help"
          tooltip="Help"
          onClick={() => { helpWidgetRef.current.showHelp() }}
        >
          <Help />
        </ToolbarButton>
      </ButtonGroup>
      <HelpWidget ref={helpWidgetRef}
        helptitle={"Simlet Editor Help"}
        helpcontent={<EditorHelpContent />}
      />
      <div style={{ display: "none" }}>
        <input
          id="uploadMediatorInput"
          type="file"
          accept=".yaml"
          style={{ display: "none" }}
        />
        {/* eslint jsx-a11y/anchor-has-content: off */}
        <a id="downloadMediatorLink" href="blob:" download="simlet.yaml"></a>
      </div>
    </div>
  )
}
