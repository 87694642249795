import React from 'react';
import Grow from '@material-ui/core/Grow';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color as AlertColor } from '@material-ui/lab/Alert';

// One of "error" | "success" | "info" | "warning"
export type Status = AlertColor;

export interface StatusSnackbarState {
  open: boolean;
  status?: Status;
  message?: string;
}

function SnackbarTransition(props: TransitionProps) {
  return <Grow {...props} />;
}

/**
 * Call props.setStatusSnackbar({ ... }) to set the state.
 * @see `StatusSnackbarState`
 * 
 * @param props
 * Required properties:
 *   statusSnackbar
 *   setStatusSnackbar
 * 
 * Optional properties:
 * Pass in `anchorOrigin` property to change the defaults. For example:
 *   anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
 */
export const StatusSnackbar = (props: any) => {
  const statusSnackbar = props.statusSnackbar
  const setStatusSnackbar = props.setStatusSnackbar

  const anchorOrigin = props.anchorOrigin || { vertical: 'top', horizontal: 'center' }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={'error' === statusSnackbar.status ? null : 3000}
      onClose={() => setStatusSnackbar({ open: false })}
      open={statusSnackbar.open}
      TransitionComponent={SnackbarTransition}
      // Set the exit duration to 0 or otherwise, for some reason, the alert 
      // visibly changes to a 'success' type with green color and OK checkmark
      transitionDuration={{ exit: 0 }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          if ('clickaway' === reason) {
            return
          }
          setStatusSnackbar({ open: false })
        }}
        severity={statusSnackbar.status}
      >
        {statusSnackbar.message}
      </MuiAlert>
    </Snackbar>
  )
}
