
const UI_PREFIX = "/studio";

const AppRoutes = {
  Index: UI_PREFIX + "/",
  Fallback: UI_PREFIX + "/about",
  About: UI_PREFIX + "/about",
  Home: UI_PREFIX + "Index",
  Simulators: UI_PREFIX + "/simulators",
  Simulations: UI_PREFIX + "/simulations",
  DslEditor: UI_PREFIX + "/dsl-editor",
  SimletTester: UI_PREFIX + "/simlet-tester",
  //WhoAmI: UI_PREFIX + "/__whoami"
};

export default AppRoutes;
