import React from 'react';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography';
import Information from 'mdi-material-ui/Information';
import useStyles from '../../modules/styles';


function PageInfoPopup(props: any) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const placement: PopperPlacementType = "bottom-start"; // "bottom";

  return (
    <div>
      <IconButton
        wa-act="act_page_info"
        size="small"
        onClick={(event) => handleClick(event)}
      >
        <Information classes={{ root: classes.infoBackgroundColor, }} />
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        open={open}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => setOpen(false)}
              >
                <Paper className={classes.popperRoot}>
                  { // arrow && 
                    (
                      <span className={classes.popperArrow} ref={setArrowRef} />
                    )
                  }
                  <Box className={classes.popperContent}>
                    {props.infoContent}
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}


const PageTitle = (props: any) => {

  // Don't render anything if page title is missing
  return (
    props?.title ? (
      <Box style={{ paddingBottom: "8px" }}>
        <div style={{ display: "inline-flex" }}>
          <Typography variant="h6" align="left" style={{ fontWeight: "bold" }}>
            {props.title}
          </Typography>
          {props?.infoContent &&
            PageInfoPopup(props)
          }
        </div>
        <Divider style={{ marginBottom: "8px" }} />
      </Box >
    ) : null
  )
}


const Page = (props: any) => {
  const classes = useStyles();

  // Optional page title 
  return (
    <div className={classes.appMain}>
      <PageTitle title={props.title} {...props} />
      {
        props.children
      }
    </div>
  )
}

export default Page;
