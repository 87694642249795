import React from 'react';
import { useMonacoEditorState } from '../modules/monacoEditorState';
import { useInitEditor } from '../modules/dslEditor';

export default function DslEditorView(props: any) {
  const EDITOR_CONTAINER_HTML_ELEMENT_ID = "editorContainer";
  const editorRef = React.useRef(document.getElementById(EDITOR_CONTAINER_HTML_ELEMENT_ID) as HTMLDivElement);

  const { initEditor } = useInitEditor();
  const editorState = useMonacoEditorState();

  React.useEffect(() => {
    const editorContainer = editorRef.current;
    const initializedEditor = initEditor(editorContainer, editorState);

    initializedEditor.onDidBlurEditorWidget(() => {
      editorState.textModel = initializedEditor.getModel();
      editorState.viewState = initializedEditor.saveViewState();
    });

    props.setEditor(initializedEditor);

    // Use editorRef and NOT editorRef.current as a dependency
    /* eslint react-hooks/exhaustive-deps: off */
  }, [editorRef]);

  return (
    <div
      id={EDITOR_CONTAINER_HTML_ELEMENT_ID}
      ref={editorRef}
      style={{
        height: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        padding: "0px",
        width: "100%",
      }}
    >
    </div>
  )
}
