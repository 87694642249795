import React from 'react';
// FUTURE import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// FUTURE import AppRoutes from '../modules/routes';
import StyledButton from './shared/StyledButtons';
import Page from './shared/Page';
// import useStyles from '../modules/styles';
// --- monaco yaml editor
import * as monaco from 'monaco-editor';
import DslEditorCommandPalette from './DslEditorCommandPalette';
import DslEditorView from './DslEditorView';
// --- fetch
// FUTURE import { fetch as fetchPolyfill } from 'whatwg-fetch';
// FUTURE import { Headers as FetchPolyfillHeaders } from 'whatwg-fetch';


// THE ORIGINAL Editor-only on the page component
// export default function DslEditor(props: any) {
//   const classes = useStyles();
//
//   // The editor, once initialized, is needed by the command palette
//   const [editor, setEditor] = React.useState<monaco.editor.IStandaloneCodeEditor>();
//
//   return (
//     <div className={classes.appMain}>
//       <DslEditorCommandPalette editor={editor} />
//       <DslEditorView setEditor={setEditor} {...props} />
//     </div>
//   );
// }

// FUTURE: move to simlets.ts 
// async function deploy(simletText: string): Promise<boolean | string> {
//   const baseUrl = adminProxyUrl(org);

//   const simName = 'cors-hello-world-sim' // 'default'
//   const apiKey = 'apisimulator'

//   const createSimletsEndpointPattern = '/api/v1/apisims/{simName}/simlets'
//   const createSimletsMethod = 'POST'

//   const createSimletsEndpoint = createSimletsEndpointPattern.replace('{simName}', simName)
//   const uri = baseUrl + createSimletsEndpoint

//   const httpHeaders = new FetchPolyfillHeaders()
//   httpHeaders.append('apisimulator-api-key', apiKey)
//   httpHeaders.append('content-type', 'application/apisim+yaml')

//   const httpRequestInit: RequestInit = {
//     method: createSimletsMethod,
//     headers: httpHeaders,
//     mode: (window.location.origin !== baseUrl ? 'cors' : 'same-origin'),
//     // TODO signal: abortSignal,
//     body: simletText,
//   };

//   let statusMsg: string;

//   const fetchPromise: Promise<Response> = fetchPolyfill(uri, httpRequestInit);
//   return fetchPromise.then((response: Response) => {
//     const statusCode = response.status
//     console.log("Deploy result: " +
//       "status=" + statusCode + " (" + response.statusText + ")"
//     )

//     switch (statusCode) {
//       case 201:
//         return true;

//       case 401: // Unauthorized. Missing API key header or invalid key.
//         // The request did pass API key header so the value must be invalid
//         return "Invalid API Key"

//       case 400: // Bad Request.The body contains a JSON-formatted error message
//       case 404: // Not Found.The body contains a JSON-formatted error message
//         break;

//       case 500:
//       default:
//         return "Unexpected status code=" + statusCode
//     }

//     const result: Promise<boolean | string> = response.json()
//       .then((json) => {
//         statusMsg = json.errors[0].msg
//         console.log(statusMsg)
//         return statusMsg
//       })
//       .catch((error) => {
//         statusMsg = "Getting the response body as JSON failed with " + error
//         console.log(statusMsg)
//         return statusMsg
//       })

//     return result
//   }).catch((error) => {
//     // The fetch polyfill errors out with "Network request failed" 
//     // message when unable to connect (e.g. server is down) in both
//     // Chrome and Firefox unless the timeout kicks in before the time
//     // the browser waits to establish a connection is up (it seems it
//     // is different per browser)
//     const errMsg: string = statusMsg // && statusMsg.trim().length > 0
//       ? statusMsg
//       : (
//         error.message
//           ? (error.message.toLowerCase().indexOf("network") >= 0
//             ? "Unable to talk to the server! Network problem, server down, or CORS issue?"
//             : "Request failed with '" + error.message + "'"
//           )
//           : "Request failed with '" + error + "'"
//       )
//     return Promise.resolve(statusMsg ? statusMsg : errMsg);
//   }).finally(() => {
//     // TODO clearTimeout(timeoutId);
//   });
// }

export default function DslEditor(props: any) {
  // const classes = useStyles();
  // FUTURE const history = useHistory();

  // The editor, once initialized, is needed by the command palette
  const [editor, setEditor] = React.useState<monaco.editor.IStandaloneCodeEditor>();

  const handleClickDeploy = (simletText: string | undefined) => {
    if (!simletText || simletText.trim().length === 0) {
      const statusMsg = 'Nothing to deploy'
      props.setStatusSnackbar({
        open: true,
        status: 'info',
        message: statusMsg
      })
      return
    }

    props.setStatusSnackbar({
      open: true,
      status: 'info',
      message: "Coming soon (Deploy a Simlet)"
    })

    // FUTURE
    // // TODO cancelControllerRef
    // const resultPromise = deploy(simletText); // , cancelControllerRef.current);
    // resultPromise
    // // cancellableSimPromiseRef.current = makeCancellable(resultPromise);
    // // cancellableSimPromiseRef.current.promise
    // .then((result: boolean | string) => {
    //   // alert(result);
    //   let status: Status
    //   let message: string
    //   if ("boolean" === typeof result) {
    //     status = (result ? 'success' : 'error')
    //     message = 'Deployment was successful'
    //   }
    //   else {
    //     status = 'error'
    //     message = result
    //   }
    //   props.setStatusSnackbar({
    //     open: true,
    //     status: status,
    //     message: message,
    //   })
    // })
    // .catch(({ isCanceled, ...error }) => {
    //   // Having the catch block supresses this error in the console
    //   // when the promise is cancelled:
    //   // "Uncaught(in promise) { isCanceled: true }"
    //   ; // Nothing to do
    // })
    // .finally(() => {
    //   // // Initialize for another potential cancelation
    //   // if (cancelControllerRef.current.signal.aborted) {
    //   //   cancelControllerRef.current = new AbortController();
    //   // }
    //
    //   // // Don't call setIsSimInProgress to set state if the promise 
    //   // // has been cancelled or it will otherwise cause "Can't perform a
    //   // // React state update on an unmounted component. This is a no-op, 
    //   // // but it indicates a memory leak in your application." upon 
    //   // // navigating away while the fetch call is in progress
    //   // if (!cancellableSimPromiseRef.current?.isCanceled()) {
    //   //   setIsSimInProgress(false);
    //   // }
    // });
  }

  return (
    <Page title="Simlet Editor"
      infoContent={
        <div>
          <Box>
            Model Simlets in the Editor with suggestions, auto-completion, and more.
          </Box>
          <Box>
            Deploy the Simlet to a running Simulation right from here (FUTURE)
          </Box>
        </div>
      }
    >
      <Grid
        container
        item
        alignItems="stretch"
        direction="row"
        xs={12}
        style={{
          // 33px is the height of the view title; 
          // 8 px for page title divider padding
          // 8px for top and bottom padding
          height: "calc(100% - 33px - 8px - 8px - 8px)",
        }}
      >
        <Grid
          item
          xs={8}
          style={{
            minWidth: "780px",
            height: "100%",
            // 360px for Editor and 36px for Command Palette
            minHeight: "calc(360px + 36px)",
          }}
        >
          <Grid item>
            <DslEditorCommandPalette editor={editor} />
          </Grid>
          <Grid item
            style={{
              // 36px for the command palette, 16px for top and bottom padding
              height: "calc(100% - 36px - 16px)",
              paddingTop: "2px",
              width: "100%",
            }}
          >
            <DslEditorView setEditor={setEditor} {...props} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="stretch"
          direction="column"
          xs
          spacing={2}
          style={{
            height: "100%",
            marginLeft: "8px",
          }}
        >
          <Grid item
            style={{
              // The height of the command pallete container
              height: "36px",
            }}
          >
            &nbsp;
          </Grid>
          <Grid
            item
          >
            <StyledButton
              wa-act="act_deploy_simlet"
              color="primary"
              //disabled={ editor is blank or doesn't contains valid simlet definition }
              onClick={() => {
                const simletText = editor?.getValue()
                handleClickDeploy(simletText)
              }}
              size="medium"
              variant="contained"
            >
              {"Deploy"}
            </StyledButton>
          </Grid>
          { /* FUTURE
          <Grid item>
            <ContainedPrimaryButton
              color="primary"
              //disabled={ editor not blank and contains valid simlet definition }
              onClick={() => history.push(AppRoutes.SimletTester)}
              size="medium"
              variant="contained"
            >
              {"Test It"}
            </ContainedPrimaryButton>
          </Grid>
          */ }
          <Grid item>{" "}</Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
