import React from 'react';
import * as monaco from 'monaco-editor';

export interface MonacoEditorState {
  textModel: monaco.editor.ITextModel | null;
  viewState: monaco.editor.ICodeEditorViewState | null;
}

const initialStateData: MonacoEditorState = {
  textModel: null,
  viewState: null,
}

const monacoEditorStateContext = React.createContext(initialStateData);

export function MonacoEditorStateProvider(props: any) {
  const editorState = React.useState<MonacoEditorState | undefined>();
  return <monacoEditorStateContext.Provider value={ editorState } {...props } />
}

export const useMonacoEditorState = () => {
  const context = React.useContext(monacoEditorStateContext)
  return context;
}
