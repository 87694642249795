import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Page from './shared/Page';
// import useStyles from '../modules/styles';


export default function Simulations(props: any) {
  // const classes = useStyles();

  return (
    <Page title="Manage Simulations">
      <Box alignSelf="center">
        <Typography variant="h3">
          {"Coming soon..."}
        </Typography>
        <Typography variant="body1">
          {"Here you'll be able to manage all your API Simulations in one place"}
        </Typography>
      </Box>
    </Page>
  );
}
