import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from 'mdi-material-ui/Close';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import StyledButton from './StyledButtons';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography 
      className={classes.root} 
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const HelpWidget = React.forwardRef((props: any, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(
    ref,
    () => ({
      showHelp() {
        setOpen(true)
      }
    })
  )

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        //maxWidth="sm"
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title"
          style={{
            backgroundColor: "#EEF7E7",
          }}
          onClose={handleClose}
          {...props}
        >
          {props.helptitle}
          { /*
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{props.helpTitle}</div>
            <div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton >
            </div>
          </div>
          */ }
        </DialogTitle>
        <DialogContent dividers={true}>
          {props.helpcontent}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose}>
            Got It
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default HelpWidget;
