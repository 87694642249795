import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import AppRoutes from '../modules/routes';
import useStyles from '../modules/styles';
import UserMenu from './UserMenu';

function AppHomeImage() {
  return (
    <img src="/chameleon-logo.svg" width="64" height="48" alt="Chameo"
      style={{
        verticalAlign: "middle",
        filter: "invert(58%) sepia(63%) saturate(4314%) hue-rotate(108deg) brightness(93%) contrast(97%)"
      }}
    />
  );
}

/* eslint @typescript-eslint/no-unused-vars: off */
function MenuActionTabs() {
  const history = useHistory();
  const classes = useStyles();

  const location = useLocation();
  const urlPath = location.pathname;

  var initialValue;
  if (AppRoutes.SimletTester === urlPath) {
    initialValue = 2;
  } else if (AppRoutes.DslEditor === urlPath) {
    initialValue = 1;
  } else {
    initialValue = 1; //0;
  }
  const value = initialValue;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 1:
        history.push(AppRoutes.DslEditor);
        break;

      case 2:
        history.push(AppRoutes.SimletTester);
        break;

      default:
      case 0:
        history.push(AppRoutes.Index);
        break;
    }

    // Do NOT set the value because it changes the selected tab while a
    // different URL is loaded in the history and as result the selected
    // tab indication is messed up when going back/forth in history
    // setValue(newValue);
  };

  // <Paper square variant="outlined">
  // </Paper>
  //label="API Simlet Modeler"
  // Don't use TabIndicatorProps - it sometimes doesn't show on the selected tab
  return (
    <div>
      { /*
      <div style={{ display: "inline-block", verticalAlign: "super", }}>
        <AppHomeImage />
        <Typography
          variant="h5"
          style={{
            display: "inline-block",
            color: "#000000",
            verticalAlign: "middle",
            fontWeight: 600,
            marginRight: "24px",
          }}
        >
          API Simlet Editor
      </Typography>
      </div>
      */ }
      <div style={{ display: "inline-block", verticalAlign: "middle", }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          classes={{
            indicator: classes.noTabIndicator,
          }}
          style={{
            display: "inline-block",
          }}
          aria-label="menu tabs"
        >
          { /*
        <Tab
          icon={<AppHomeImage />}
          label=""
          value={0}
          disabled={true}
          classes={{
            textColorPrimary: classes.menuTabTextColorPrimary,
          }}
          style={{
            paddingTop: 0,
            textTransform: "none",
          }}
        />
        */ }
          <Tab
            label="DSL Editor"
            value={1}
            disabled={value === 1}
            classes={{
              textColorPrimary: classes.menuTabTextColorPrimary,
            }}
            style={{
              fontWeight: "bold",
              padding: "0px",
              textTransform: "none",
            }}
          />
          <Tab
            label="Form Editor"
            value={2}
            disabled={value === 2}
            classes={{
              textColorPrimary: classes.menuTabTextColorPrimary,
            }}
            style={{
              fontWeight: "bold",
              padding: "0px",
              textTransform: "none",
            }}
          />
        </Tabs>
      </div>
    </div>
  );

  // // color: "#04b545"
  // return (
  //   <div>
  //     <div
  //       style={{
  //         display: "inline-block",
  //       }}
  //     >
  //       <AppHomeImage />
  //       <Typography
  //         variant="h5"
  //         style={{
  //           display: "inline-block",
  //           color: "#000000",
  //           verticalAlign: "middle",
  //           fontWeight: 600,
  //         }}
  //       >
  //         API Simlet Editor
  //       </Typography>
  //       <div style={{ display: "inline-block", marginLeft: "24px", marginRight: "24px" }}>
  //         <Button
  //           onClick={() => {
  //             history.push(AppRoutes.DslEditor);
  //           }}
  //         >
  //           DSL Editor
  //         </Button>
  //       </div>
  //       <div style={{ display: "inline-block", marginRight: "24px" }}>
  //         <Button
  //           onClick={() => {
  //             history.push(AppRoutes.FormEditor);
  //           }}
  //         >
  //           Form Editor
  //         </Button>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default function Header(props: any) {
  // const classes = useStyles();

  // const auth = useAuth();
  //
  // if (!auth.isAuthenticated) {
  //   return <div>&nbsp;</div>;
  // }

  return (
    <TopAppBar {...props} />
  );
}

function TopAppBar(props: any) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
          }}
        >
          <div style={{ display: "inline-block", verticalAlign: "super", }}>
            <AppHomeImage />
            <Typography
              variant="h5"
              style={{
                display: "inline-block",
                color: "#000000",
                verticalAlign: "middle",
                fontWeight: 800,
                marginRight: "4px",
              }}
            >
              API Simulator Cloud Studio
          </Typography>
          <Typography 
            variant="caption"
            style={{
              color: "#000000",
            }}
          >
            <sup>(alpha)</sup>
          </Typography>
          </div>
          {
            // <MenuActionTabs />
          }
          <UserHeaderComponent {...props} />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
};

function UserHeaderComponent(props: any) {
  // // Get auth state and re-render anytime it changes
  // const auth = useAuth();

  // return auth.user ? (
  //   <Typography variant="body2">Hello, {auth.user.name}!</Typography>
  // ) : (
  //     <Typography variant="body2">Hello, Stranger!</Typography>
  //   );

  const userMenuRef = React.useRef<any>();

  return (
    <div style={{ display: "inline-flex", alignItems: "center", }}>
      <Typography
        style={{
          display: "inline-block",
          color: "#000000",
          verticalAlign: "middle",
          // marginRight: "8px",
        }}
        variant="subtitle2"
      >
        Hello, Stranger!
      </Typography>
      <Box>
        <Button
          wa-act="act_user_menu"
          onClick={(event) => {
            userMenuRef.current.showMenu(event.currentTarget)
          }}
        >
          <Avatar />
        </Button>
        <UserMenu ref={userMenuRef} {...props} />
      </Box>
    </div>
  );
};
